import { alpha, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  select: {
    fontSize: '16px',
    lineHeight: '24px',
    padding: '0',
    width: '100%',
    display: 'flex',
    color: theme.palette.activeText.main
  },
  root: {
    color: theme.palette.activeText.main,
    padding: '8px 16px',
    cursor: 'pointer',

    '& fieldset': {
      borderColor: '#A8B1CE !important'
    }
  },

  disabled: {
    color: alpha(theme.palette.activeText.main, 0.5),
    '& fieldset': {
      borderColor: `${alpha('#A8B1CE', 0.5)} !important`
    }
  },

  icon: {
    width: '24px',
    height: '24px',
    color: '#A8B1CE !important',
    fill: '#A8B1CE !important'
  },
  tooltip: {
    fontSize: '14px',
    lineHeight: '20px',
    backgroundColor: '#A8B1CE',
    color: 'white'
  },
  tooltipArrow: {
    color: '#A8B1CE'
  },
  menuItem: {
    fontSize: '16px',
    lineHeight: '24px',
    padding: '12px 32px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.activeText.main
  }
}));
