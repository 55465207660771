import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import DateTimePickerInput from '~/components/atoms/DateTimePickerInput';
import { eventEmitter } from '~/services/eventEmitter';
import { createEditAttendanceSchema } from '~/utils/Validations/attendanceManager';

import { useStyles } from './styles';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';

export const CreateEditAttendanceForm = ({
  onCloseModal,
  initialValues,
  onSubmit
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      title: initialValues?.title || '',
      duration: initialValues?.duration || '',
      date: initialValues?.date ? new Date(initialValues.date) : null
    },
    resolver: yupResolver(createEditAttendanceSchema)
  });

  const submit = async (data) => {
    if (loading) return;
    setLoading(true);
    onSubmit && (await onSubmit({ attendance: { ...data } }));
    setLoading(false);
    eventEmitter.emit('resetAttendancesData');
    onCloseModal && onCloseModal();
  };

  return (
    <Box
      component="form"
      className={classes.form}
      onSubmit={handleSubmit(submit)}
    >
      <Box>
        <Typography className={classes.label}>Título da ata</Typography>
        <TextField
          {...register('title')}
          variant="outlined"
          placeholder="Escreva o título aqui..."
          hiddenLabel
          fullWidth
          inputProps={{
            className: classes.textInput
          }}
          className={classes.textfield}
        />
        {errors.title && (
          <Typography className={classes.errorMessage}>
            {errors.title.message}
          </Typography>
        )}
      </Box>
      <Box>
        <Typography className={classes.label}>Carga horária</Typography>
        <TextField
          {...register('duration')}
          variant="outlined"
          placeholder="Escreva a carga horária aqui..."
          hiddenLabel
          fullWidth
          inputProps={{
            className: classes.textInput
          }}
          className={classes.textfield}
        />
        {errors.duration && (
          <Typography className={classes.errorMessage}>
            {errors.duration.message}
          </Typography>
        )}
      </Box>
      <Box>
        <Typography className={classes.label}>Data</Typography>
        <Controller
          name="date"
          control={control}
          render={({ field }) => (
            <DateTimePickerInput
              {...field}
              onChange={(value) => setValue('date', value)}
              value={field.value}
              placeholder="Selecione a data..."
            />
          )}
        />
        {errors.date && (
          <Typography className={classes.errorMessage}>
            {errors.date.message}
          </Typography>
        )}
      </Box>
      <Box className={classes.actionButtons}>
        <Button
          type="reset"
          className={classes.cancelButton}
          variant="text"
          onClick={onCloseModal}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="contained"
          className={classes.confirmButton}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Salvar'}
        </Button>
      </Box>
    </Box>
  );
};

CreateEditAttendanceForm.propTypes = {
  onCloseModal: PropTypes.func,
  initialValues: PropTypes.shape({
    title: PropTypes.string,
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
  }),
  onSubmit: PropTypes.func.isRequired
};
