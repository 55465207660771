import React from 'react';
import { Controller } from 'react-hook-form';

import { ErrorValidation } from '~/components/atoms/ErrorValidation/index';

import * as S from './styles';
import PropTypes from 'prop-types';

export const ReactHookFormController = ({
  control,
  name,
  Component,
  mask,
  error
}) => {
  const handleChange = (event) => {
    const { value } = event.target;
    return mask ? mask(value) : value;
  };

  return (
    <S.Wrapper>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Component
              {...field}
              onChange={(event) => field.onChange(handleChange(event))}
            />
          );
        }}
      />
      {!!error && <ErrorValidation message={error} />}
    </S.Wrapper>
  );
};

ReactHookFormController.propTypes = {
  control: PropTypes.any,
  name: PropTypes.any,
  Component: PropTypes.any,
  error: PropTypes.any,
  mask: PropTypes.func
};

ReactHookFormController.defaultProps = {
  control: null,
  name: null,
  Component: null,
  error: null
};
