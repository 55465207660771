import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import { createCertificateConfiguration, findByID } from '~/services/Space';

import CertificateConfiguration from '../../molecules/CertificateConfiguration';
import { useStyles } from './styles';
import { Box, CircularProgress, Divider } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

export default function SpaceAdvancedOptions() {
  const classes = useStyles();
  const [space, setSpace] = useState({});
  const [loading, setLoading] = useState(true);
  const { spaceID } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const getSpace = async () => {
    try {
      setLoading(true);
      const response = await findByID(spaceID);
      setSpace(response);
    } catch (error) {
      enqueueSnackbar('Erro ao buscar informações do espaço', {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSpace();
  }, [spaceID]);

  return (
    <Box className={classes.container}>
      {!loading ? (
        <Box>
          <CertificateConfiguration
            structure={space}
            createCertificateConfiguration={createCertificateConfiguration}
            hasPresence={space.current_attendable === 'Space'}
          />
          <Divider className={classes.divider} />
        </Box>
      ) : (
        <CircularProgress
          size={64}
          style={{
            margin: 'auto'
          }}
        />
      )}
    </Box>
  );
}

SpaceAdvancedOptions.propTypes = {
  space: PropTypes.object.isRequired
};
