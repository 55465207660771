import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  workloadInput: {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.activeText.main,
    padding: '12px 16px'
  },
  helpText: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#A8B1CE',
    width: '100%'
  },
  fieldBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  }
}));
