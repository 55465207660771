import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  picker: {
    width: '100%',
    '& input': {
      fontSize: '16px',
      padding: '12px 16px',
      lineHeight: '24px',
      color: theme.palette.activeText.main
    }
  },
  label: {
    marginTop: 10,
    marginBottom: 5
  },
  endAdornment: {
    display: 'flex',
    alignItens: 'center',
    gap: '8px',
    cursor: 'pointer'
  },
  icon: {
    fontSize: '24px',
    color: theme.palette.primary.main,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  }
}));
