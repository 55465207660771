import React      from 'react';
import Text       from '~/components/atoms/Text';
import DeleteIcon from '@material-ui/icons/Delete';

import { IconWrapper }            from '~/utils/forms';
import { Button, Grid, useTheme } from '@material-ui/core';
import { useFormContext }         from 'react-hook-form';
import { removeAuthorization }    from './fetch';
import i18n from '~/I18n';

import settings from '~settings';

export default function IntegrationDone() {
  const theme = useTheme();
  const { watch, setValue } = useFormContext()

  const handleClick = () => {
    var answer = window.confirm("Tem certeza que deseja remover a integração com o PagSeguro?")
    if (answer) removeAuthorization(watch("pagseguro_authorization").id).then(handleSuccess).catch(handleFailure)
  }

  const handleSuccess = () => {
    setValue("pagseguro_authorization", null)
    setValue("enable_pagseguro", false)
  }

  const handleFailure = () => {
    alert("Não foi possível remover a integração!")
  }

  return (
    <Grid container direction="column">
      <Grid item container spacing={2} alignItems='center'>

        <Grid item>
          <Button disabled style={{ backgroundColor: theme.palette.primary.main, textTransform: 'none', color: 'white' }}>
            <Text style={{ padding: 0, margin: 0}}> {`${i18n.t('StructuresName.Environment.SingularUpper')}`} integrado ao PagSeguro! </Text>
          </Button>
        </Grid>
        
        <Grid item>
          <Button variant="outlined" color="primary" style={{ textTransform: 'none' }} onClick={handleClick}>
            <Grid container spacing={1}>
              <Grid item>
                <IconWrapper color="primary" Icon={DeleteIcon} fontSize={18}/>
              </Grid>
              <Grid item>
                <Text style={{ padding: 0, margin: 0}} color="primary"> Remover integração </Text>
              </Grid>
            </Grid>
          </Button>
        </Grid>
        
      </Grid>
      
      <Grid item>
        <a
          target="_blank"
          href={`${settings.baseUrl}/static/tutorial_remover_integracao_pagseguro.pdf`}
          rel="noreferrer"
        >
          <Text variant="mediumText" color="accent">
           Clique aqui e saiba como remover a integração do {`${i18n.t('StructuresName.Environment.SingularLower')}`} com o PagSeguro.
          </Text>
        </a>
      </Grid>
    </Grid>
  );
}