import React, { useState } from 'react';

import { useStyles } from './styles';
import {
  Box,
  Button,
  Fade,
  Modal,
  TextField,
  Typography
} from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import PropTypes from 'prop-types';

export const EditAttendanceConfirmationModal = ({
  open,
  onCancel,
  onConfirm,
  confirmationKeyword
}) => {
  const classes = useStyles();
  const [confirmationString, setConfirmationString] = useState('');
  const [error, setError] = useState(false);

  const handleClose = () => {
    setConfirmationString('');
    setError(false);
    onCancel();
  };

  const handleConfirmation = () => {
    if (confirmationString === confirmationKeyword) {
      onConfirm();
      setConfirmationString('');
      setError(false);
    } else setError(true);
  };

  const handleInputChange = (e) => {
    setConfirmationString(e.target.value);
    setError(false);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      className={classes.modal}
    >
      <Fade in={open}>
        <Box className={classes.content}>
          <Box className={classes.title}>
            <WarningRoundedIcon className={classes.warningIcon} />
            <Typography className={classes.titleText}>Aviso</Typography>
          </Box>
          <Box className={classes.body}>
            <Typography className={classes.text}>
              <strong>Esta ação não poderá ser desfeita.</strong> Alterar as
              configurações dessa opção acarretará na perda de todas as
              frequências já registradas.
            </Typography>
            <Typography className={classes.text}>
              Digite a palavra <strong>{confirmationKeyword}</strong> para
              confirmar.
            </Typography>
          </Box>
          <Box className={classes.inputFiled}>
            <TextField
              inputProps={{
                className: classes.textInput
              }}
              onChange={handleInputChange}
              value={confirmationString}
              variant="outlined"
              hiddenLabel
              placeholder={confirmationKeyword}
            />
            {error && (
              <Typography className={classes.errorMessage}>
                A palavra digitada está incorreta.
              </Typography>
            )}
          </Box>
          <Box className={classes.footer}>
            <Button
              onClick={handleClose}
              variant="text"
              disableRipple
              className={classes.cancelButton}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              className={classes.confirmButton}
              onClick={handleConfirmation}
              disableRipple
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

EditAttendanceConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmationKeyword: PropTypes.string.isRequired
};
