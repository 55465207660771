import React from 'react';

import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

export const ModalContent = ({ children, className, ...rest }) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.root, className)} {...rest}>
      {children}
    </Box>
  );
};

ModalContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

ModalContent.defaultProps = {
  children: null,
  className: ''
};
