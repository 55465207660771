import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F2F2F7',
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '4px'
  },
  button: {
    display: 'flex',
    gap: '8px',
    color: theme.palette.activeText.main
  },
  buttonIcon: {
    width: '16px',
    height: '16px',
    color: theme.palette.activeText.main
  },
  buttonText: {
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.palette.activeText.main,
    whiteSpace: 'nowrap',
    textTransform: 'none'
  },
  itensCountText: {
    fontSize: '14px',
    lineHeight: '24px',
    color: theme.palette.activeText.main
  }
}));
