import React from 'react';

import { update } from '~/services/presence';

import { useStyles } from './styles';
import { Box, Modal, Typography, IconButton, Button } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

export const MarkAttendanceModal = ({ open, onClose, presence }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleAttendance = async () => {
    try {
      await update(presence.id);
      enqueueSnackbar('Presença registrada com sucesso.', {
        variant: 'success'
      });
      onClose();
    } catch (e) {
      enqueueSnackbar(
        'Erro ao registrar presença, por favor tente novamente mais tarde.',
        { variant: 'error' }
      );
    }
  };

  return (
    <Modal className={classes.modal} open={open} onClose={onClose}>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Box className={classes.title}>
            <EventAvailableIcon className={classes.titleIcon} />
            <Typography className={classes.titleText}>
              Registrar presença
            </Typography>
          </Box>
          <IconButton
            onClick={onClose}
            disableRipple
            className={classes.closeButton}
          >
            <CloseRoundedIcon className={classes.closeIcon} />
          </IconButton>
        </Box>
        <Typography className={classes.description}>
          Essa mídia registra sua frequência.{' '}
          <strong>Clique no botão abaixo para confirmar sua presença.</strong>
        </Typography>
        <Button
          disableRipple
          variant="contained"
          className={classes.confirmButton}
          onClick={handleAttendance}
        >
          Registrar
        </Button>
      </Box>
    </Modal>
  );
};

MarkAttendanceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  presence: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.bool
  })
};

MarkAttendanceModal.defaultProps = {
  attendance: null
};
