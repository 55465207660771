import * as yup from 'yup';

export const spaceValidations = yup
  .object()
  .shape({
    name: yup
      .string()
      .required('Este campo é obrigatório.')
      .max(80, 'O máximo de caracteres deste campo é 80'),
    workload: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Este campo é obrigatório.')
      .typeError('Este campo deve ser um número.')
      .min(1, 'Adicione um valor maior que zero.')
  })
  .required();
