import api from '~/services/api';

const baseURL = '/api/settings';

export const getSettings = async () => {
  const { data } = await api.get(baseURL);

  return data;
};

export default {
  getSettings
};
