import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  subsection: {
    marginTop: 40,
    marginBottom: 40,
    width: '100%'
  },
  subtitleContainer: {
    marginTop: 5,
    marginBottom: 20
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gridGap: '16px',
    width: '100%'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  submitArea: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttons: {
    display: 'flex',
    width: '220px',
    justifyContent: 'space-between'
  },
  saveButton: {
    '& button:hover': {
      backgroundColor: theme.palette.tertiary.main
    }
  },
  errorMessage: {
    marginTop: 12,
    marginLeft: 8,
    fontSize: 12,
    color: theme.palette.error.main
  }
}));
