import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  field: {
    marginBottom: '20px'
  },
  content: {
    marginTop: '15px',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '0 5px 10px 0'
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  }
});
