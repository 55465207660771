import * as yup from 'yup';

export const createEditAttendanceSchema = yup.object().shape({
  title: yup.string().required('O título é obrigatório.'),
  duration: yup
    .number()
    .required('A carga horária é obrigatória.')
    .typeError('A carga horária deve ser um número.')
    .positive('A carga horária deve ser maior que zero.'),
  date: yup
    .string()
    .nullable()
    .required('A data é obrigatória.')
    .test('is-valid-date', 'Por favor, selecione uma data válida.', (value) => {
      return !isNaN(new Date(value).getTime());
    })
});

export const editLectureAttendanceSchema = yup.object().shape({
  date: yup.date().required('A data inicial é obrigatória.'),
  lock_self_presence_at: yup
    .date()
    .required('A data final é obrigatória.')
    .when('date', (date, schema) => {
      return schema.min(date, 'A data final deve ser maior que a inicial.');
    }),
  duration: yup
    .number()
    .required('A carga horária é obrigatória.')
    .typeError('A carga horária deve ser um número.')
    .positive('A carga horária deve ser maior que zero.')
});
