import React from 'react';
import { useFormContext, useController } from 'react-hook-form';

import Text from '~/components/atoms/Text';

import { Box, useTheme } from '@material-ui/core';
import { AsYouType } from 'libphonenumber-js';
import PropTypes from 'prop-types';

const ReactHookFormTextField = ({
  label,
  name,
  defaultValue,
  isPhone,
  mask,
  ...rest
}) => {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext();

  const { field } = useController({ name, control });

  const theme = useTheme();

  const styleInput = {
    fontSize: 12,
    lineHeight: 1.3,
    height: 38.25,
    padding: 12,
    width: '100%',
    position: 'relative',
    backgroundColor: 'white',
    borderRadius: 5,
    border: `1px solid ${theme.palette.primary.main} `,
    '&::placeholder': {
      color: theme.palette.textActiveElement.main,
      opacity: 0.75
    }
  };

  const phoneValue = (value) => {
    if (value) {
      if (value?.length === 3) {
        return value?.replace(')', '');
      }

      return new AsYouType('BR').input(value ?? '');
    }

    return field.value;
  };

  const handleChange = (event) => {
    console.log('handleCHange');
    if (mask) {
      const value = mask(event.target.value);
      field.onChange(value);
    } else {
      field.onChange(event.target.value);
    }
  };

  return (
    <Box style={{ width: '100%', position: 'relative' }}>
      <Text variant="label" color={'gray'}>
        {label}
      </Text>
      <input
        {...rest}
        value={isPhone ? phoneValue(field.value) : field.value}
        defaultValue={defaultValue}
        style={styleInput}
        variant="outlined"
        error={!!errors[name]}
        helperText={errors[name]?.message ?? ''}
        fullWidth
        margin="dense"
        multiline
        rows={1}
        rowsMax={3}
        disabled={false}
        {...register(name)}
        onChange={handleChange}
      />
    </Box>
  );
};

export default ReactHookFormTextField;

ReactHookFormTextField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  isPhone: PropTypes.bool,
  mask: PropTypes.func
};

ReactHookFormTextField.defaultProps = {
  name: '',
  label: '',
  defaultValue: '',
  isPhone: false
};
