import React from 'react';
import { useFormContext } from 'react-hook-form';

import Switch from '~/components/atoms/Switch';

import { useStyles } from './styles';
import { Box, Typography } from '@material-ui/core';

export const CpfRequired = () => {
  const classes = useStyles();
  const { watch, setValue } = useFormContext();
  return (
    <Box className={classes.summary}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography className={classes.title}>
          Habilitar coleta de CPF
        </Typography>
        <Switch
          checked={watch('cpf_required')}
          onChange={(event) => setValue('cpf_required', event.target.checked)}
        />
      </Box>
      <Typography className={classes.description}>
        Se essa opção estiver ativa, somente membros com CPF preenchido poderão
        ingressar no ambiente.
      </Typography>
    </Box>
  );
};
