import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dateCell: {
    padding: '16px 24px !important',
    fontSize: '16px !important',
    lineHeight: '24px !important',
    color: `${theme.palette.primary.main} !important`,
    borderRight: `1px solid #A8B1CE`
  },
  titleCell: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px',
    borderRight: `1px solid #A8B1CE`
  },
  primaryText: {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.primary.main
  },
  secondaryText: {
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.palette.activeText.main
  },
  statusIndicator: {
    display: 'flex',
    padding: '8px 24px',
    borderRadius: '20px',
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    width: 'fit-content'
  }
}));
