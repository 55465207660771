import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '24px',
    color: theme.palette.activeText.main
  }
}));
