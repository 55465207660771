import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Switch from '~/components/atoms/Switch';
import { EditAttendanceConfirmationModal } from '~/components/organisms/EditAttendanceConfirmationModal';
import i18n from '~/I18n';

import { useStyles } from './styles';
import {
  Box,
  Collapse,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core';

export const Attendance = () => {
  const classes = useStyles();

  const { watch, setValue, getValues } = useFormContext();

  const [show, setShow] = useState(Boolean(watch('current_attendable')));
  const [newValue, setNewValue] = useState(null);
  const [confirmationKeyword, setConfirmationKeyword] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const defaultCurrentAttendable = useMemo(
    () => getValues('current_attendable'),
    []
  );

  const handleSwitchChange = () => {
    if (defaultCurrentAttendable && show) {
      setConfirmationKeyword('Desativar');
      setNewValue(null);
      setOpenModal(true);
    } else {
      setValue('current_attendable', defaultCurrentAttendable);
      setShow((prev) => !prev);
    }
  };

  const handleChangeOption = (event) => {
    if (
      defaultCurrentAttendable &&
      event.target.value !== defaultCurrentAttendable
    ) {
      setConfirmationKeyword('Editar');
      setNewValue(event.target.value);
      setOpenModal(true);
    } else {
      setValue('current_attendable', event.target.value);
    }
  };

  const handleConfirmation = () => {
    setValue('current_attendable', newValue);
    if (newValue === null) setShow(false);
    setOpenModal(false);
  };

  const handleCancel = () => {
    setOpenModal(false);
    if (newValue !== null) {
      setValue('current_attendable', defaultCurrentAttendable);
    }
  };

  return (
    <>
      <Box className={classes.summary}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.title}>
            Habilitar registro de frequência
          </Typography>
          <Switch checked={show} onChange={handleSwitchChange} />
        </Box>
        <Typography className={classes.description}>
          Se essa opção estiver ativa, será possível registrar a frequência dos
          membros.
        </Typography>
      </Box>
      <Collapse in={show}>
        <RadioGroup
          name="current_attendable"
          className={classes.radioGroup}
          onChange={handleChangeOption}
          value={watch('current_attendable') || null}
        >
          <FormControlLabel
            value="Course"
            control={<Radio className={classes.radio} />}
            label={
              <Typography className={classes.radioLabel}>
                {`Registrar frequência em ${i18n.t(
                  `StructuresName.Course.PluralLower`
                )}.`}
              </Typography>
            }
          />
          <FormControlLabel
            value="Space"
            control={<Radio className={classes.radio} />}
            label={
              <Typography className={classes.radioLabel}>
                {`Registrar frequência em ${i18n.t(
                  `StructuresName.Space.PluralLower`
                )}.`}
              </Typography>
            }
          />
        </RadioGroup>
      </Collapse>

      <EditAttendanceConfirmationModal
        open={openModal}
        onCancel={handleCancel}
        onConfirm={handleConfirmation}
        confirmationKeyword={confirmationKeyword}
      />
    </>
  );
};
