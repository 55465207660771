import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import PopUpMenu from '~/components-refactor/molecules/PopUpMenu';
import Item from '~/components-refactor/molecules/PopUpMenu/Item';
import { ConfirmationDeleteAttendanceModal } from '~/components/organisms/AttendanceManager/ConfirmationDeleteAttendanceModal';
import { ManagerAttendanceModal } from '~/components/organisms/AttendanceManager/ManagerAttendanceModal';
import { CreateEditAttendanceForm } from '~/components/organisms/AttendanceManager/ManagerAttendanceModal/CreateEditAttendanceForm';
import { EditLectureAttendanceForm } from '~/components/organisms/AttendanceManager/ManagerAttendanceModal/EditLectureAttendanceForm';
import { update as updateAttendance } from '~/services/attendance';

import { useStyles } from './styles';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

export const HeaderCell = ({ attendance }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [openEditAttendanceModal, setOpenEditAttendanceModal] = useState(false);
  const [openDeleteAttendanceModal, setOpenDeleteAttendanceModal] = useState(
    false
  );

  const textRef = useRef(null);
  const [isEllipsized, setIsEllipsized] = useState(false);

  useEffect(() => {
    const checkEllipsis = () => {
      if (textRef.current) {
        const element = textRef.current;
        setIsEllipsized(element.scrollWidth > element.clientWidth);
      }
    };

    checkEllipsis();

    window.addEventListener('resize', checkEllipsis);

    return () => {
      window.removeEventListener('resize', checkEllipsis);
    };
  }, [attendance]);

  const handleUpdateAttendance = async (data) => {
    try {
      await updateAttendance(attendance.id, data);
    } catch {
      enqueueSnackbar('Erro ao atualizar registro de frequência', {
        variant: 'error'
      });
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.infoContainer}>
        <Typography className={classes.secondary}>
          {new Date(attendance.date).toLocaleDateString('pt-BR', {
            timeZone: 'UTC'
          })}
        </Typography>
        <Tooltip
          disableHoverListener={!isEllipsized}
          title={attendance?.lecture?.name || 'Ata de frequência'}
          arrow
          placement="top"
          classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
        >
          {attendance.lecture ? (
            <Link
              to={`/dashboard/lecture/${attendance.lecture.id}`}
              className={classes.primary}
              style={{
                textDecoration: 'underline'
              }}
              ref={textRef}
            >
              {attendance.lecture.name}
            </Link>
          ) : (
            <Typography ref={textRef} className={classes.primary}>
              {attendance.title}
            </Typography>
          )}
        </Tooltip>
        <Typography className={classes.secondary}>
          CH: {attendance.duration}H
        </Typography>
      </Box>
      <PopUpMenu
        component={(props) => (
          <IconButton {...props} className={classes.iconButton}>
            <MoreVertIcon className={classes.icon} />
          </IconButton>
        )}
      >
        <Item
          icon={EditIcon}
          text="Editar"
          action={() => setOpenEditAttendanceModal(true)}
        />
        <Item
          icon={DeleteIcon}
          text="Excluir"
          action={() => setOpenDeleteAttendanceModal(true)}
        />
      </PopUpMenu>
      <ManagerAttendanceModal
        open={openEditAttendanceModal}
        onClose={() => {
          setOpenEditAttendanceModal(false);
        }}
        title="Editar registro de frequência"
      >
        {attendance.lecture ? (
          <EditLectureAttendanceForm
            initialValues={attendance}
            onSubmit={handleUpdateAttendance}
          />
        ) : (
          <CreateEditAttendanceForm
            initialValues={attendance}
            onSubmit={handleUpdateAttendance}
          />
        )}
      </ManagerAttendanceModal>
      <ConfirmationDeleteAttendanceModal
        open={openDeleteAttendanceModal}
        onClose={() => setOpenDeleteAttendanceModal(false)}
        attendance={attendance}
      />
    </Box>
  );
};

HeaderCell.propTypes = {
  attendance: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    date: PropTypes.string.isRequired,
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    lecture: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired
    })
  }).isRequired
};
