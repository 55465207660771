import React from 'react';

import { ReactComponent as TropicalIslandImage } from '~/assets/images/tropical-island-cuate.svg';
import GenericFeedbackLayout from '~/components/molecules/GenericFeedbackLayout';
import { useAttendanceContext } from '~/contexts/AttendanceManager';

import { Pagination } from '../../../molecules/Pagination';
import { ContextualBar } from './ContextualBar';
import { useStyles } from './styles';
import { Table } from './Table';
import { Box } from '@material-ui/core';

export const DataDisplay = () => {
  const classes = useStyles();
  const {
    getPresences,
    paginationData,
    loading,
    presences,
    filters
  } = useAttendanceContext();

  const handleChangePagination = (page, perPage) => {
    getPresences(page, perPage);
  };

  const handleNoContentMessage = () => {
    if (filters?.date) {
      return {
        title: 'Nenhuma ata de frequência encontrada! ',
        description:
          'Crie uma nova ata de frequência no botão acima ou adicione mídias com registro de presença habilitado para essa data nas opções avançadas.'
      };
    }
    if (filters?.search) {
      return {
        title: 'Aluno não encontrado.',
        description: ''
      };
    }
    return {
      title: 'Lista de Frequência Vazia!',
      description:
        'Crie uma nova ata de frequência no botão acima ou adicione mídias com registro de presença habilitado nas opções avançadas.'
    };
  };

  return (
    <>
      {!loading && presences && Object.keys(presences).length === 0 ? (
        <GenericFeedbackLayout
          ImageIcon={TropicalIslandImage}
          title={handleNoContentMessage().title}
          description={handleNoContentMessage().description}
        />
      ) : (
        <Box className={classes.root}>
          <ContextualBar />
          <Table />
          <Pagination
            totalItens={paginationData.total_users}
            handleChange={handleChangePagination}
            page={paginationData.page}
            perPage={paginationData.perPage}
          />
        </Box>
      )}
    </>
  );
};
