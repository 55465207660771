import React from 'react';

// import { Switch as MaterialSwitch, useTheme } from '@material-ui/core';
import Text from '../Text';
import { useStyles, CustomSwitch } from './styles';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function Switch({
  label,
  input,
  checked: propChecked,
  onChange: propOnChange
}) {
  const checked = input.value || propChecked;
  const onChange = input.onChange || propOnChange;
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.container}>
      <CustomSwitch checked={!!checked} onChange={onChange} />
      {label && (
        <Text variant="text" color="gray">
          {label}
        </Text>
      )}
    </div>
  );
}

Switch.propTypes = {
  label: PropTypes.string,
  input: PropTypes.shape(),
  checked: PropTypes.bool,
  onChange: PropTypes.func
};

Switch.defaultProps = {
  label: '',
  input: {},
  checked: false,
  onChange: () => {}
};
