import React from 'react';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import settings from '~settings';

import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const currentTabsNameMap = {
  [i18n.t('Information')]: 'information',
  [i18n.t('Reports')]: 'reports',
  [i18n.t('Members')]: 'members',
  [i18n.t('AdvancedOptions')]: 'advancedOptions',
  Frequência: 'attendance'
};

export default function DisplayOptions({
  optionNames,
  selectedOption,
  setSelectedOption,
  tabSpacing,
  unclickable
}) {
  const classes = useStyles({ tabSpacing, unclickable });

  function replaceCurrentTabParam(newParam) {
    const url = new URL(window.location.href);

    url.searchParams.set('currentTab', newParam);

    window.history.pushState({}, '', url);
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        {optionNames.map((name) => (
          <Text
            key={currentTabsNameMap[name]}
            color={
              selectedOption === currentTabsNameMap[name] ? 'primary' : 'gray'
            }
            variant="environmentSidebarText"
            style={{ marginBottom: 15 }}
            onClick={() => {
              if (!unclickable) {
                setSelectedOption(currentTabsNameMap[name]);
                replaceCurrentTabParam(currentTabsNameMap[name]);
              }
            }}
          >
            {name}
          </Text>
        ))}
        <a
          href={settings.helpCenterUrl}
          target={'_blank'}
          style={{ fontSize: 16, color: 'gray' }}
          rel="noreferrer"
        >
          {i18n.t('Manual')}
        </a>
      </Box>
    </Box>
  );
}

DisplayOptions.propTypes = {
  optionNames: PropTypes.array,
  selectedOption: PropTypes.number,
  setSelectedOption: PropTypes.func,
  tabSpacing: PropTypes.string,
  unclickable: PropTypes.bool
};

DisplayOptions.defaultProps = {
  optionNames: [],
  selectedOption: 0,
  setSelectedOption: null,
  tabSpacing: 'flex-start',
  unclickable: false
};
