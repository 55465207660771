import React, { useState } from 'react';

import { destroy } from '~/services/attendance';
import { eventEmitter } from '~/services/eventEmitter';

import { useStyles } from './styles';
import {
  Box,
  Button,
  Fade,
  IconButton,
  Modal,
  Typography
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

export const ConfirmationDeleteAttendanceModal = ({
  open,
  onClose,
  attendance
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    if (loading) return;
    try {
      setLoading(true);
      await destroy(attendance.id);
      eventEmitter.emit('resetAttendancesData');
      onClose();
    } catch {
      enqueueSnackbar('Erro ao excluir registro de frequência', {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal className={classes.modal} open={open} onClose={onClose}>
      <Fade in={open}>
        <Box className={classes.root}>
          <Box className={classes.header}>
            <Box className={classes.title}>
              <WarningRoundedIcon className={classes.titleIcon} />
              <Typography className={classes.titleText}>
                Excluir registro de frequência
              </Typography>
            </Box>
            <IconButton onClick={onClose} className={classes.closeButton}>
              <CloseRoundedIcon className={classes.closeIcon} />
            </IconButton>
          </Box>
          <Typography className={classes.description}>
            Esta ação não poderá ser desfeita. O registro de frequência{' '}
            <strong>
              {`Data: ${new Date(attendance.date).toLocaleDateString('pt-BR', {
                timeZone: 'UTC'
              })} - CH: ${attendance.duration}`}
            </strong>{' '}
            será permanentemente excluído.
          </Typography>
          <Box className={classes.actionButtons}>
            <Button
              variant="text"
              className={classes.cancelButton}
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              onClick={handleDelete}
              variant="contained"
              className={classes.deleteButton}
              disabled={loading}
            >
              Excluir
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

ConfirmationDeleteAttendanceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  attendance: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    date: PropTypes.string.isRequired,
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired
  }).isRequired
};
