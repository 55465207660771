import React, { useState, useRef, useMemo } from 'react';
import { MdUpload } from 'react-icons/md';
import { MdPersonRemove } from 'react-icons/md';
import { MdPersonAddAlt1 } from 'react-icons/md';
import { MdDelete } from 'react-icons/md';

import Text from '~/components/atoms/Text';
import api from '~/services/api';
import settings from '~settings';

import { useStyles } from './styles';
import { useTheme } from '@material-ui/core';
import {
  Dialog,
  Box,
  DialogContent,
  Typography,
  Link,
  ButtonBase,
  CircularProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const feedBackMessages = {
  add: {
    success:
      'Sua solicitação de adicionar membros nessa estrutura esta sendo processada, mais informações foram enviadas por e-mail',
    failure:
      'Algo deu errado ao tentar adicionar membros nesta estrutura, por favor verifique o arquivo e tente novamente'
  },
  remove: {
    success:
      'Sua solicitação de remover membros dessa estrutura esta sendo processada, mais informações foram enviadas por e-mail',
    failure: 'Algo deu errado ao tentar remover membros desta estrutura'
  }
};

function formatFileSize(bytes) {
  if (bytes < 1024) return bytes + ' Bytes';
  else if (bytes < 1048576) return (bytes / 1024).toFixed(2) + ' KB';
  else if (bytes < 1073741824) return (bytes / 1048576).toFixed(2) + ' MB';
  else return (bytes / 1073741824).toFixed(2) + ' GB';
}

export default function ImportMemberModal({
  structureID,
  showImportMemberModal,
  closeImportMemberModal,
  loadMembersDetails,
  importMembersFromFileService,
  removeMembersFromFileService,
  membersLimitReached,
  reportURLs
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { enqueueSnackbar } = useSnackbar();

  const fileInputRef = useRef();
  const [removeMembers, setRemoveMembers] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const handleDeleteFile = () => {
    setFile(null);
    fileInputRef.current.value = '';
  };

  const closeModal = () => {
    handleDeleteFile();
    closeImportMemberModal();
  };

  const handleSendFile = async () => {
    setUploadingFile(true);
    const fileData = new FormData();
    fileData.append('uploaded_file', file);
    try {
      const service = removeMembers
        ? removeMembersFromFileService
        : importMembersFromFileService;
      await service(structureID, fileData);
      enqueueSnackbar(
        feedBackMessages[removeMembers ? 'remove' : 'add'].success,
        { variant: 'success' }
      );
      loadMembersDetails();
      closeModal();
    } catch {
      enqueueSnackbar(
        feedBackMessages[removeMembers ? 'remove' : 'add'].failure,
        { variant: 'error' }
      );
      handleDeleteFile();
    }
    setUploadingFile(false);
  };

  // Talvez seja interessante criar um serviço ou uma função "getFile"
  const downloadReport = async () => {
    const url = removeMembers ? reportURLs.remove : reportURLs.import;
    const errorMessage = `Não existe relatório de ${
      removeMembers ? 'remoção' : 'importação'
    } para esta estrutura.`;

    try {
      const response = await api.get(url, { responseType: 'blob' });
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));

      const fileLink = document.createElement('a');
      fileLink.href = fileURL;

      fileLink.setAttribute('download', 'filename.csv');
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
      window.URL.revokeObjectURL(fileURL);
    } catch (err) {
      enqueueSnackbar(errorMessage, {
        variant: 'error'
      });
    }
  };

  const exampleSpreadsheetLink = useMemo(
    () =>
      removeMembers
        ? `${settings.baseUrl}/static/planilha-modelo-de-remocao-de-membros.xlsx`
        : `${settings.baseUrl}/static/planilha-modelo-de-importacao-de-membros.xlsx`,
    [removeMembers]
  );

  return (
    <Dialog
      open={showImportMemberModal}
      onClose={closeModal}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          padding: '32px 24px'
        }
      }}
    >
      <>
        <Box className={classes.header}>
          <Typography className={classes.textHeader}>
            Importar Membros
          </Typography>
          <CloseIcon className={classes.exitButton} onClick={closeModal} />
        </Box>
      </>
      <DialogContent>
        <Box className={classes.topButtons}>
          <Box
            style={{ flex: 1, display: 'flex', justifyContent: 'center' }}
            className={`${!removeMembers && classes.topButtonSelected}`}
          >
            <ButtonBase
              style={{ padding: '16px', gap: '8px' }}
              disableRipple
              onClick={() => setRemoveMembers(false)}
            >
              <MdPersonAddAlt1 style={{ fontSize: '28px' }} />
              <Typography style={{ fontSize: '20px', fontWeight: 500 }}>
                Adicionar por importação
              </Typography>
            </ButtonBase>
          </Box>
          <Box
            style={{ flex: 1, display: 'flex', justifyContent: 'center' }}
            className={`${removeMembers && classes.topButtonSelected}`}
          >
            <ButtonBase
              style={{ padding: '16px', gap: '8px' }}
              disableRipple
              onClick={() => setRemoveMembers(true)}
            >
              <MdPersonRemove style={{ fontSize: '28px' }} />
              <Typography style={{ fontSize: '20px', fontWeight: 500 }}>
                Remover por Importação
              </Typography>
            </ButtonBase>
          </Box>
        </Box>
        <Box style={{ color: '#676D82' }}>
          <ButtonBase className={classes.reportButton} onClick={downloadReport}>
            Baixar Relatório
          </ButtonBase>
          <Typography
            style={{ fontSize: '16px', fontWeight: 500, marginTop: '24px' }}
          >
            Passo a passo importação para{' '}
            {removeMembers ? 'remover' : 'adicionar'} membros:
          </Typography>
          <Box
            style={{ color: '#676D82', marginTop: '8px', marginBottom: '64px' }}
          >
            <Typography style={{ fontSize: '16px', fontWeight: 400 }}>
              1. Baixe o modelo de importação no formato .xlsx,&nbsp;
              <Link href={exampleSpreadsheetLink}>aqui</Link>;
            </Typography>
            <Typography style={{ fontSize: '16px', fontWeight: 400 }}>
              2. Preencha os dados do(s) membro(s);
            </Typography>
            <Typography style={{ fontSize: '16px', fontWeight: 400 }}>
              3. Anexe abaixo e submeta clicando no botão{' '}
              {removeMembers ? '"Remover"' : '"Adicionar"'}.
            </Typography>
            {membersLimitReached && !removeMembers && (
              <Box className={classes.userLimitReachedWarning}>
                <InfoIcon
                  style={{ color: 'white', marginRight: 8, fontSize: 'large' }}
                />
                <Text variant="lg" color="black">
                  <b style={{ fontWeight: 500 }}>
                    Limite de incrições atingido.{' '}
                  </b>{' '}
                  Não é possível adicionar novos membros ao curso.
                </Text>
              </Box>
            )}
          </Box>
          <Box style={{ marginBottom: '24px' }}>
            <input
              type="file"
              id="ImportFile"
              accept=".xlsx"
              style={{ display: 'none' }}
              onChange={handleFileChange}
              ref={fileInputRef}
            />
            <label htmlFor="ImportFile">
              <Box className={classes.inputFile}>
                <MdUpload style={{ fontSize: '24px' }} />
                <Typography style={{ fontSize: '16px' }}>
                  Selecione o Arquivo
                </Typography>
              </Box>
            </label>
            {file ? (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '8px'
                }}
              >
                <Typography style={{ fontSize: '14px', color: '#0056B4' }}>
                  {file.name}
                </Typography>
                <Box style={{ display: 'flex' }}>
                  <Typography style={{ fontSize: '16px', color: '#676D82' }}>
                    {formatFileSize(file.size)}
                  </Typography>
                  <ButtonBase onClick={handleDeleteFile}>
                    <MdDelete
                      style={{ fontSize: '24px', marginLeft: '16px' }}
                    />
                  </ButtonBase>
                </Box>
              </Box>
            ) : (
              <Typography style={{ fontSize: '12px', marginTop: '8px' }}>
                Apenas arquivos .xlsx são permitidos
              </Typography>
            )}
          </Box>
        </Box>
        <Box className={classes.footerButtons}>
          <ButtonBase
            disableRipple
            className={classes.cancelButton}
            onClick={closeModal}
          >
            Cancelar
          </ButtonBase>
          <ButtonBase
            disabled={
              (membersLimitReached && !removeMembers) || !file || uploadingFile
            }
            disableRipple
            className={`${classes.importButton} ${
              removeMembers
                ? classes.importButtonRemove
                : classes.importButtonAdd
            }`}
            onClick={handleSendFile}
          >
            {uploadingFile ? (
              <CircularProgress size={20} />
            ) : removeMembers ? (
              'Remover'
            ) : (
              'Adicionar'
            )}
          </ButtonBase>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

ImportMemberModal.propTypes = {
  structureID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  showImportMemberModal: PropTypes.bool.isRequired,
  closeImportMemberModal: PropTypes.func.isRequired,
  loadMembersDetails: PropTypes.func.isRequired,
  importMembersFromFileService: PropTypes.func.isRequired,
  removeMembersFromFileService: PropTypes.func.isRequired,
  membersLimitReached: PropTypes.bool.isRequired,
  reportURLs: PropTypes.shape({
    import: PropTypes.string.isRequired,
    remove: PropTypes.string.isRequired
  }).isRequired
};
