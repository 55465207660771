import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import styled from 'styled-components';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    minHeight: '100vh',
    overflowY: 'hidden',
    width: '100vw'
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '100vh',
    overflowY: 'hidden',
    paddingTop: '60px',
    '@media (max-width: 600px)': {
      paddingLeft: 20,
      maxWidth: '100%',
      overflowX: 'hidden'
    }
  },
  sidebar: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '@media (max-width: 600px)': {
      display: 'none'
    }
  },
  tabBar: {
    display: 'none',
    '@media (max-width: 600px)': {
      display: 'block'
    }
  },
  sidebarContent: {
    paddingTop: 180
  },
  infos: {
    width: '80vw',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 600px)': {
      width: '100vw'
    }
  },
  infosHeader: {
    marginTop: '34px',
    marginBottom: '35px',
    width: '100%',
    maxWidth: '100%',
    '& p': {
      maxWidth: '100%'
    }
  },
  infoIcon: {
    color: (props) => `${props.theme.palette.accent.main}`,
    marginRight: '8px'
  },
  ambientInformationText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5
  },
  infosContent: {
    overflow: 'auto',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    '& ::-webkit-scrollbar': {
      width: '5px'
    },
    '& ::-webkit-scrollbar-track': {
      background: 'none'
    },
    '& ::-webkit-scrollbar-thumb': {
      background: (props) => `${props.theme.palette.secondary.main}`,
      bordeRadius: '2px'
    }
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  certificateInfo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  certificateButton: {
    height: '38px',
    textTransform: 'none',
    fontSize: '14px',
    marginLeft: '5px',
    color: 'white'
  },
  wrapperManageButton: {
    display: 'flex',
    flex: 'auto'
  }
});

export const ShimmerText = styled(({ color, height, width, ...otherProps }) => (
  <Skeleton
    {...otherProps}
    height={height}
    animation="pulse"
    width={width}
    style={{
      backgroundColor: (props) => `${props.theme.palette.shimmer.main}`
    }}
  />
))``;
