import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import userService from '~/services/user';
import { maskCPF } from '~/utils/cpfValidator';
import { AddMemberFormSchema } from '~/utils/Validations/MembersManagement';

import { useStyles } from './style';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dialog,
  Box,
  Typography,
  OutlinedInput,
  InputLabel,
  ButtonBase,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText
} from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import debounce from 'debounce';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const roles = [
  {
    label: 'Student',
    value: 'member'
  },
  {
    label: 'Teacher',
    value: 'teacher'
  },
  {
    label: 'Tutor',
    value: 'tutor'
  },
  {
    label: 'Admin',
    value: 'environment_admin'
  }
];

const defaultFormValues = {
  email: '',
  confirm_email: '',
  first_name: '',
  last_name: '',
  mobile: '',
  role: 'default'
};
export default function AddMemberModal({
  showAddMemberModal,
  closeAddMemberModal,
  callbackSuccessAddMemberInStructure,
  setMembersLimitReached,
  membersLimitReached,
  structureID,
  checkAssociationService,
  registerUserInStructureService,
  structureService,
  cpfRequired
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { enqueueSnackbar } = useSnackbar();

  const [alreadyRegisteredUser, setAlreadyRegisteredUser] = useState(false);
  const [userFound, setUserFound] = useState(null);
  const [loadingMember, setLoadingMember] = useState(false);
  const [isDisableForm, setIsDisableForm] = useState(true);

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(AddMemberFormSchema),
    context: {
      userExist: Boolean(userFound),
      alreadyRegisteredUser: alreadyRegisteredUser,
      cpfRequired: cpfRequired
    },
    defaultValues: defaultFormValues
  });

  const disableField = useMemo(
    () =>
      alreadyRegisteredUser ||
      membersLimitReached ||
      Boolean(userFound) ||
      isDisableForm ||
      loadingMember,
    [
      alreadyRegisteredUser,
      membersLimitReached,
      userFound,
      isDisableForm,
      loadingMember
    ]
  );

  const handleInputEmail = (event) => {
    const email = event?.target?.value;
    setIsDisableForm(true);
    if (debouncedInputEmail) debouncedInputEmail.clear();
    debouncedInputEmail(email);
  };

  const debouncedInputEmail = useCallback(
    debounce(async (email) => {
      setLoadingMember(true);
      const user = await checkUserIsAlreadyRegisteredInPlatform(email);
      if (user) await checkUserIsAlreadyRegisteredInStructure(user);
      setLoadingMember(false);
      setIsDisableForm(!(email.length > 0));
    }, 1500),
    []
  );

  const checkUserIsAlreadyRegisteredInPlatform = async (email) => {
    try {
      const user = await userService.findByEmail(email);
      setValue('confirm_email', user.email);
      setValue('first_name', user.first_name);
      setValue('last_name', user.last_name);
      setValue('mobile', user.mobile);
      setValue('cpf', user.cpf);
      setUserFound(user);
      return user;
    } catch (err) {
      reset({
        email: email,
        confirm_email: '',
        first_name: '',
        last_name: '',
        password: '',
        confirmPassword: '',
        mobile: '',
        cpf: '',
        role: 'default'
      });
      setUserFound(null);
      setAlreadyRegisteredUser(false);
      return null;
    }
  };

  const checkUserIsAlreadyRegisteredInStructure = async (user) => {
    try {
      const member = await checkAssociationService(structureID, user.id);
      setValue('role', member.role);
      setAlreadyRegisteredUser(true);
    } catch (err) {
      setAlreadyRegisteredUser(false);
    }
  };

  const submitForm = async (formData) => {
    if (alreadyRegisteredUser || loadingMember) return;
    setLoadingMember(true);
    const role = formData.role;
    if (userFound) {
      await registerUserInStructure(userFound.id, role);
    } else {
      const user = await createNewUser(formData);
      if (user) await registerUserInStructure(user.id, role);
    }
    setLoadingMember(false);
  };

  const registerUserInStructure = async (userID, role) => {
    try {
      await registerUserInStructureService(structureID, userID, role);
      closeAddMemberModal();
      enqueueSnackbar(`Usuário inscrito com sucesso na estrutura.`, {
        variant: 'success'
      });
      callbackSuccessAddMemberInStructure();
    } catch (error) {
      if (error.response.data.enrollment_limit) {
        enqueueSnackbar(
          'Limite de membros atingido. Não é possível registrar novos usuários nesta estrutura.',
          { variant: 'error' }
        );
        setMembersLimitReached(true);
      } else {
        enqueueSnackbar(
          'Não foi possivel registrar o usuário nesta estrutura.',
          { variant: 'error' }
        );
      }
      closeAddMemberModal();
    }
  };

  const createNewUser = async (formUserData) => {
    delete formUserData.role;
    delete formUserData.confirm_email;
    delete formUserData.confirmPassword;
    const body = { user: formUserData };
    try {
      const user = await structureService.createUser(structureID, body);
      setUserFound(user);
      enqueueSnackbar(`Usuário criado com sucesso na plataforma`, {
        variant: 'success'
      });
      return user;
    } catch (e) {
      if (e.response.status === 422) {
        const errors = e.response.data.error;
        Object.values(errors).forEach((error) => {
          enqueueSnackbar(error, { variant: 'error' });
        });
      } else {
        enqueueSnackbar(
          `Não foi possível cadastrar o usuário na plataforma. Verifique os dados e tente novamente!`,
          { variant: 'error' }
        );
      }

      setUserFound(null);
      setAlreadyRegisteredUser(false);
      return null;
    }
  };

  useEffect(() => {
    if (showAddMemberModal) {
      reset(defaultFormValues);
      setAlreadyRegisteredUser(false);
      setUserFound(null);
      setLoadingMember(false);
      setIsDisableForm(true);
    }
  }, [showAddMemberModal]);

  return (
    <Dialog
      open={showAddMemberModal}
      onClose={closeAddMemberModal}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          padding: '32px 24px'
        }
      }}
    >
      <Box className={classes.header}>
        <Typography className={classes.textHeader}>Adicionar Membro</Typography>
        <CloseIcon
          className={classes.exitButton}
          onClick={closeAddMemberModal}
        />
      </Box>

      {membersLimitReached && (
        <Box className={classes.userLimitReachedWarning}>
          <InfoIcon
            style={{ color: 'white', marginRight: 8, fontSize: 'large' }}
          />
          <Text variant="lg" color="black">
            <b style={{ fontWeight: 500 }}>Limite de incrições atingido. </b>{' '}
            Não é possível adicionar novos membros ao curso.
          </Text>
        </Box>
      )}
      <form
        onSubmit={handleSubmit(submitForm)}
        className={classes.form}
        style={{
          maxHeight: '700px'
        }}
      >
        <Box className={classes.formBox}>
          <Box className={classes.field}>
            <InputLabel className={classes.label}>E-mail</InputLabel>
            <Controller
              name="email"
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <OutlinedInput
                  {...rest}
                  ref={ref}
                  placeholder="Digite o e-mail"
                  className={classes.input}
                  disabled={membersLimitReached || loadingMember}
                  onChange={(event) => {
                    rest.onChange(event);
                    handleInputEmail(event);
                  }}
                />
              )}
            />
            <FormHelperText
              error
              disabled={!errors.email}
              style={{ fontSize: '14px' }}
            >
              {errors.email?.message}
            </FormHelperText>
            {Boolean(userFound) && !loadingMember && (
              <Typography
                style={{
                  fontSize: '14px',
                  color: '#0056B4'
                }}
              >
                {!alreadyRegisteredUser
                  ? 'Usuário existente identificado'
                  : 'Usuário já adicionado. Tente outro e-mail'}
              </Typography>
            )}
          </Box>

          <Box className={classes.field}>
            <InputLabel className={classes.label}>Confirmar E-mail</InputLabel>
            <OutlinedInput
              {...register('confirm_email')}
              placeholder="Digite o e-mail novamente"
              inputProps={{ className: classes.input }}
              disabled={disableField}
            />
            <FormHelperText
              error
              disabled={errors.confirm_email}
              style={{ fontSize: '14px' }}
            >
              {errors.confirm_email?.message}
            </FormHelperText>
          </Box>

          <Box className={classes.field} disabled>
            <InputLabel className={classes.label}>Nome</InputLabel>
            <OutlinedInput
              {...register('first_name')}
              placeholder="Digite o nome"
              inputProps={{ className: classes.input }}
              disabled={disableField}
            />
            <FormHelperText
              error
              disabled={!errors.first_name}
              style={{ fontSize: '14px' }}
            >
              {errors.first_name?.message}
            </FormHelperText>
          </Box>
          <Box className={classes.field}>
            <InputLabel className={classes.label}>Sobrenome</InputLabel>
            <OutlinedInput
              {...register('last_name')}
              placeholder="Digite o sobrenome"
              inputProps={{ className: classes.input }}
              disabled={disableField}
            />
            <FormHelperText
              error
              disabled={!errors.last_name}
              style={{ fontSize: '14px' }}
            >
              {errors.last_name?.message}
            </FormHelperText>
          </Box>
          <Box
            style={{
              display: 'flex',
              gap: '8px'
            }}
          >
            <Box className={classes.field}>
              <InputLabel className={classes.label}>{`CPF ${
                cpfRequired ? '' : '(opcional)'
              }`}</InputLabel>
              <Controller
                name="cpf"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <OutlinedInput
                    {...register('cpf')}
                    placeholder="000.000.000-00"
                    value={value}
                    disabled={disableField}
                    onChange={(e) => onChange(maskCPF(e.target.value))}
                    inputProps={{ className: classes.input }}
                  />
                )}
              />
              <FormHelperText
                error
                disabled={!errors.cpf}
                style={{ fontSize: '14px' }}
              >
                {errors.cpf?.message}
              </FormHelperText>
            </Box>
            <Box className={classes.field}>
              <InputLabel className={classes.label}>
                Telefone celular (opcional)
              </InputLabel>
              <Controller
                name="mobile"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...rest } }) => (
                  <NumberFormat
                    {...rest}
                    customInput={OutlinedInput}
                    format={'+55 (##) #####-####'}
                    mask="_"
                    getInputRef={ref}
                    placeholder="+55 (DDD) 0 0000-0000"
                    className={classes.input}
                    disabled={disableField}
                  />
                )}
              />
              <FormHelperText
                error
                disabled={true}
                style={{ fontSize: '14px' }}
              >
                {errors.mobile?.message}
              </FormHelperText>
            </Box>
          </Box>
          <Box className={classes.field}>
            <InputLabel className={classes.label}>Senha</InputLabel>
            <OutlinedInput
              {...register('password')}
              placeholder="********"
              inputProps={{ className: classes.input }}
              disabled={disableField}
              type="password"
            />
            <FormHelperText
              error
              disabled={!errors.password}
              style={{ fontSize: '14px' }}
            >
              {errors.password?.message}
            </FormHelperText>
          </Box>
          <Box className={classes.field}>
            <InputLabel className={classes.label}>Repetir Senha</InputLabel>
            <OutlinedInput
              {...register('confirmPassword')}
              placeholder="********"
              inputProps={{ className: classes.input }}
              disabled={disableField}
              type="password"
            />
            <FormHelperText
              error
              disabled={!errors.confirmPassword}
              style={{ fontSize: '14px' }}
            >
              {errors.confirmPassword?.message}
            </FormHelperText>
          </Box>
          <Box className={classes.field}>
            <InputLabel className={classes.label}>Papel</InputLabel>
            <Select
              {...register('role')}
              variant="outlined"
              value={watch('role')}
              inputProps={{ className: classes.input }}
              disabled={
                alreadyRegisteredUser ||
                membersLimitReached ||
                isDisableForm ||
                loadingMember
              }
            >
              <MenuItem
                value="default"
                className={classes.input}
                style={{ color: '#A8B1CE' }}
              >
                <em>Selecionar papel</em>
              </MenuItem>
              {roles.map((item) => (
                <MenuItem
                  key={item}
                  value={item.value}
                  className={classes.input}
                >
                  {i18n.t(item.label)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText
              error
              disabled={!errors.role}
              style={{ fontSize: '14px' }}
            >
              {errors.role?.message}
            </FormHelperText>
          </Box>
        </Box>

        <Box className={classes.buttons}>
          <ButtonBase
            disableRipple
            className={classes.cancelButton}
            onClick={closeAddMemberModal}
          >
            Cancelar
          </ButtonBase>
          <ButtonBase
            disabled={
              alreadyRegisteredUser ||
              membersLimitReached ||
              isDisableForm ||
              loadingMember
            }
            type="submit"
            disableRipple
            className={classes.addButton}
          >
            {!loadingMember ? (
              'Adicionar membro'
            ) : (
              <CircularProgress size={20} color="primary" />
            )}
          </ButtonBase>
        </Box>
      </form>
    </Dialog>
  );
}

AddMemberModal.propTypes = {
  showAddMemberModal: PropTypes.bool.isRequired,
  closeAddMemberModal: PropTypes.func.isRequired,
  callbackSuccessAddMemberInStructure: PropTypes.func.isRequired,
  setMembersLimitReached: PropTypes.func.isRequired,
  membersLimitReached: PropTypes.bool.isRequired,
  structureID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  checkAssociationService: PropTypes.func.isRequired,
  registerUserInStructureService: PropTypes.func.isRequired,
  environmentId: PropTypes.number,
  structureService: PropTypes.func.isRequired,
  cpfRequired: PropTypes.bool
};
