import React from 'react';

import { Modal } from '~/components-refactor/molecules/Modal';
import { ModalContent } from '~/components-refactor/molecules/Modal/ModalContent';
import { ModalFooter } from '~/components-refactor/molecules/Modal/ModalFooter';
import { ModalHeader } from '~/components-refactor/molecules/Modal/ModalHeader';

import { useStyles } from './styles';
import { Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

export const CpfRequiredModal = ({ handleConfirm, onClose, open }) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      classes={{
        root: classes.root
      }}
    >
      <ModalHeader>
        <Typography className={classes.title}>
          Atualização de dados necessária
        </Typography>
      </ModalHeader>
      <ModalContent className={classes.content}>
        <Typography className={classes.description}>
          Para acessar esse ambiente é necessário que você preencha o campo CPF.
        </Typography>
        <Typography className={classes.description}>
          Vá até o seu perfil e atualize seus dados pessoais.
        </Typography>
      </ModalContent>
      <ModalFooter>
        <Button
          variant="text"
          className={classes.cancelButton}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          className={classes.confirmButton}
          onClick={handleConfirm}
        >
          Atualizar meus dados
        </Button>
      </ModalFooter>
    </Modal>
  );
};

CpfRequiredModal.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};
