import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  name: {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.primary.main,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  tooltip: {
    fontSize: '14px',
    lineHeight: '20px',
    backgroundColor: '#A8B1CE',
    color: 'white'
  },
  tooltipArrow: {
    color: '#A8B1CE'
  }
}));
