import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import { useStyles } from './styles';
import {
  Box,
  lighten,
  TableCell,
  Typography,
  useTheme
} from '@material-ui/core';
import PropTypes from 'prop-types';

const statusNameMap = {
  present: 'Presente',
  absent: 'Ausente',
  justified: 'Justificada',
  unknown: 'Não Atribuída'
};

const statusColorMap = (theme) => ({
  present: {
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.main, 0.9)
  },
  absent: {
    color: '#454954',
    backgroundColor: lighten('#454954', 0.9)
  },
  justified: {
    color: '#454954',
    backgroundColor: lighten('#454954', 0.9)
  },
  unknown: {
    color: 'white',
    backgroundColor: theme.palette.primary.main
  }
});

export const TableRowContent = ({ presence }) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <>
      <TableCell align="left" className={classes.dateCell}>
        {new Date(presence.attendance.date).toLocaleDateString('pt-BR', {
          timeZone: 'UTC'
        })}
      </TableCell>
      <TableCell align="left" className={classes.titleCell}>
        {presence.attendance.lecture_id ? (
          <Link
            to={`/dashboard/lecture/${presence.attendance.lecture_id}`}
            className={classes.primaryText}
            style={{ textDecoration: 'underline' }}
          >
            {presence.attendance.title}
          </Link>
        ) : (
          <Typography className={classes.primaryText}>
            {presence.attendance.title}
          </Typography>
        )}
        <Typography
          className={classes.secondaryText}
        >{`CH: ${presence.attendance.duration}H`}</Typography>
      </TableCell>
      <TableCell align="left">
        <Box
          component="span"
          className={classes.statusIndicator}
          style={{
            ...(statusColorMap(theme)[presence.status] ||
              statusColorMap(theme).unknown)
          }}
        >
          {statusNameMap[presence.status]}
        </Box>
      </TableCell>
    </>
  );
};

TableRowContent.propTypes = {
  presence: PropTypes.shape({
    attendance: PropTypes.shape({
      date: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      lecture_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }).isRequired,
    status: PropTypes.oneOf(['present', 'absent', 'justified', 'unknown'])
      .isRequired
  }).isRequired
};
