import React from 'react';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

import { useSpacePageContext } from '../contexts/SpacePageContext';
import { Image } from './Image';
import { Box } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const Sidebar = () => {
  const classes = makeStyles((theme) => ({
    sidebar: {
      width: '20vw',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft: '6vw',
      [theme.breakpoints.down('sm')]: {
        width: '96vw',
        padding: 0
      }
    },
    sidebarContent: {
      paddingTop: 180,
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '8px',
        textTransform: 'uppercase',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 16px'
      }
    },
    text: {
      marginBottom: 15,
      fontWeight: 'normal',
      fontSize: '16px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 0
      }
    }
  }))();
  const theme = useTheme();
  const { section, setSection, space } = useSpacePageContext();

  return (
    <Box className={classes.sidebar}>
      <Box display={{ xs: 'none', md: 'flex' }}>
        <Image />
      </Box>
      <Box className={classes.sidebarContent}>
        <Text
          style={{
            color: section === 'content' ? theme.palette.primary.main : 'gray'
          }}
          variant="environmentSidebarText"
          className={classes.text}
          onClick={() => setSection('content')}
        >
          {i18n.t('Content')}
        </Text>
        <Text
          style={{
            color:
              section === 'discussion' ? theme.palette.primary.main : 'gray'
          }}
          variant="environmentSidebarText"
          className={classes.text}
          onClick={() => setSection('discussion')}
        >
          {i18n.t('Discussion')}
        </Text>
        <Text
          style={{
            color: section === 'members' ? theme.palette.primary.main : 'gray'
          }}
          variant="environmentSidebarText"
          onClick={() => {
            setSection('members');
          }}
          className={classes.text}
        >
          {i18n.t('Members')}
        </Text>
        {space.current_attendable === 'Space' && !space.can_manage && (
          <Text
            style={{
              color:
                section === 'attendance' ? theme.palette.primary.main : 'gray'
            }}
            variant="environmentSidebarText"
            onClick={() => {
              setSection('attendance');
            }}
            className={classes.text}
          >
            Frequência
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default Sidebar;
