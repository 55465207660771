import * as yup from 'yup';

export const editLectureValidations = yup
  .object()
  .shape({
    name: yup.string().required('Este campo é obrigatório.'),
    lectureable: yup.object({
      type: yup.string(),
      exercise: yup.object().when('type', {
        is: 'exercise',
        then: yup.object().shape({
          questions: yup
            .array()
            .min(1, 'É necessário criar pelo menos uma questão.')
            .required('É necessário criar pelo menos uma questão.')
        })
      })
    }),
    attendance: yup
      .object()
      .shape({
        enable: yup.boolean().required(),
        date: yup
          .string()
          .nullable()
          .when('enable', {
            is: true,
            then: yup
              .string()
              .required('O início do registro de frequência é obrigatório.')
              .nullable(false)
              .test(
                'date-before-lock',
                'A data inicial não pode ser anterior ao término do registro de frequência.',
                function(value) {
                  const { lock_self_presence_at } = this.parent;
                  return (
                    !value ||
                    !lock_self_presence_at ||
                    new Date(value).getTime() <=
                      new Date(lock_self_presence_at).getTime()
                  );
                }
              ),
            otherwise: yup
              .string()
              .notRequired()
              .nullable(true)
          }),
        duration: yup
          .number()
          .typeError('A duração deve ser um número válido.')
          .when('enable', {
            is: true,
            then: yup
              .number()
              .min(1, 'A carga horaria não pode ser inferior a 0.')
              .typeError('A duração deve ser um número válido.')
              .required('A duração é obrigatória.')
              .nullable(false),
            otherwise: yup
              .number()
              .typeError('A duração deve ser um número válido.')
              .notRequired()
              .nullable(true)
          }),
        lock_self_presence_at: yup
          .string()
          .nullable()
          .when('enable', {
            is: true,
            then: yup
              .string()
              .nullable()
              .required('A data é obrigatória.')
              .test(
                'is-valid-date',
                'Por favor, selecione uma data válida.',
                (value) => {
                  return !isNaN(new Date(value).getTime());
                }
              ),
            otherwise: yup
              .string()
              .notRequired()
              .nullable(true)
          })
      })
      .notRequired()
  })
  .required();

yup
  .string()
  .nullable()
  .required('A data é obrigatória.')
  .test('is-valid-date', 'Por favor, selecione uma data válida.', (value) => {
    return !isNaN(new Date(value).getTime());
  });
