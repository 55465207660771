import React from 'react';
import { useFormContext } from 'react-hook-form';

import Switch from '~/components/atoms/Switch';
import settings from '~settings';

import IntegrationDone from './IntegrationDone';
import IntegrationUnDone from './IntegrationUnDone';
import { useStyles } from './styles';
import { Box, Collapse, Typography } from '@material-ui/core';

export default function PagseguroIntegration() {
  const classes = useStyles();
  const { watch, setValue } = useFormContext();

  if (settings.client === 'avamec') {
    return null;
  }

  return (
    <>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent="space-between"
      >
        <Typography className={classes.title}>
          Integrar com o PagSeguro
        </Typography>
        <Switch
          checked={watch('enable_pagseguro')}
          onChange={() =>
            setValue('enable_pagseguro', !watch('enable_pagseguro'))
          }
        />
      </Box>

      <Collapse in={watch('enable_pagseguro')} style={{ marginTop: '10px' }}>
        {watch('pagseguro_authorization') ? (
          <IntegrationDone />
        ) : (
          <IntegrationUnDone />
        )}
      </Collapse>
    </>
  );
}
