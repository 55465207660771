import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  dateCell: {
    fontSize: '16px',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '8px 0px 0px 0px',
    padding: '16px 24px 16px 24px',
    width: '20%'
  },
  attendanceCell: {
    fontSize: '16px',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: '16px 24px 16px 24px',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  dateFilterCell: {
    fontSize: '16px',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: '16px 24px 16px 24px',
    borderRadius: '0px 8px 0px 0px',
    width: '30%'
  },
  dateFilterContainer: {
    maxWidth: '250px',
    marginLeft: 'auto'
  }
}));
