import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import DragDropFileInput from '~/components/atoms/DragDropFileInput';
import Input from '~/components/atoms/TextInput';
import { DatePickerInputForm } from '~/components/molecules/DatePickerInputForm';
import Field from '~/components/organisms/GenericFormWrapper/Field';
import i18n from '~/I18n';
import CertificateService from '~/services/Certificate';
import { CertificateValidations } from '~/utils/CertificateValidations/yup';
import { convertDateToLocal } from '~/utils/dates';
import { TextPrimary } from '~/utils/forms';

import { useStyles } from './styles';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Modal,
  Box,
  Typography,
  Button,
  Grid,
  Switch
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const CertificateFormModal = ({
  open,
  onClose,
  certificateConfig,
  setCertificateConfig,
  ID,
  workload,
  createCertificateConfiguration,
  hasPresence
}) => {
  let defaultValues = {
    startDate: certificateConfig?.start_date
      ? convertDateToLocal(certificateConfig.start_date)
      : null,
    endDate: certificateConfig?.end_date
      ? convertDateToLocal(certificateConfig.end_date)
      : null,
    responsibleName: certificateConfig?.responsible_for_signing ?? '',
    responsiblePosition: certificateConfig?.signature_owner_position ?? '',
    organizationName: certificateConfig?.signature_owner_organization ?? '',
    workload: workload,
    minimumPercentage: certificateConfig?.minimum_rate_for_completion ?? '',
    isCoursePeriod: certificateConfig?.is_period ?? false,
    minPresence: certificateConfig?.min_presence ?? ''
  };

  const isEdit = !!certificateConfig;
  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(CertificateValidations(isEdit, hasPresence))
  });

  const { watch } = methods;
  const isCoursePeriod = methods.watch('isCoursePeriod');
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const fileTypes = [
    'image/bmp',
    'image/x-windows-bmp',
    'image/gif',
    'image/jpeg',
    'image/pjpeg',
    'image/png'
  ];

  const submitCertificate = (values, formData) => {
    formData.append(
      'certificate_configuration[responsible_for_signing]',
      values.responsibleName
    );
    formData.append(
      'certificate_configuration[signature_owner_position]',
      values.responsiblePosition
    );
    formData.append(
      'certificate_configuration[signature_owner_organization]',
      values.organizationName
    );
    formData.append(
      'certificate_configuration[minimum_rate_for_completion]',
      values.minimumPercentage
    );

    formData.append(
      'certificate_configuration[is_period]',
      values.isCoursePeriod
    );

    formData.append(
      'certificate_configuration[min_presence]',
      values.minPresence
    );

    if (values.isCoursePeriod) {
      formData.append(
        'certificate_configuration[start_date]',
        values.startDate
      );
      formData.append('certificate_configuration[end_date]', values.endDate);
    }

    if (values.signature) {
      formData.append(
        'certificate_configuration[signature_attributes][image]',
        values.signature
      );
    }

    if (values.logo) {
      formData.append(
        'certificate_configuration[logo_attributes][image]',
        values.logo
      );
    }

    if (certificateConfig) {
      return CertificateService.updateCertificateConfiguration(
        certificateConfig.id,
        formData
      );
    } else {
      return createCertificateConfiguration(ID, formData);
    }
  };

  const onSubmit = async (data) => {
    const formData = new FormData();

    submitCertificate(data, formData)
      .then(handleSucess)
      .catch(handleFailure);
  };

  const handleSucess = (data) => {
    let message = certificateConfig ? 'atualizado' : 'gerado';

    enqueueSnackbar(`Certificado foi ${message} com sucesso!`, {
      variant: 'success'
    });
    setCertificateConfig(data);
    onClose();
  };

  const handleFailure = (data) => {
    let message = certificateConfig ? 'atualizar' : 'gerar';

    enqueueSnackbar(`Erro ao ${message} configuração do certificado.`, {
      variant: 'error'
    });
    onClose();
  };

  const handlePropagation = (event) => {
    if (typeof event.preventDefault === 'function') {
      event.preventDefault();
    }
    if (typeof event.stopPropagation === 'function') {
      event.stopPropagation();
    }

    methods.handleSubmit(onSubmit)(event);
  };

  return (
    <FormProvider {...methods}>
      <Modal open={open} onClose={onClose}>
        <form onSubmit={handlePropagation}>
          <Box className={classes.modal}>
            <Typography variant="h6" className={classes.modalTitle}>
              {certificateConfig ? 'Editar Certificado' : 'Criar Certificado'}
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.dataConfigurationTitle}
            >
              Configurações dos Dados
            </Typography>
            <Box className={classes.workloadFlag}>
              <ErrorOutlineIcon className={classes.infoIcon} />
              <Typography variant="body2" className={classes.workloadText}>
                {`Carga horária: ${workload} ${
                  workload > 1 ? 'horas' : 'hora'
                }`}
              </Typography>
            </Box>
            <Grid container spacing={2} style={{ marginBottom: 2 }}>
              <Grid item xs={12} md={6}>
                <Field
                  label={TextPrimary('Nome do Responsável', 13.5, 'gray')}
                  name="responsibleName"
                  colorScheme="gray"
                  Component={Input}
                  placeholder={`Insira o nome do Responsável`}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  label={TextPrimary('Cargo do Responsável', 13.5, 'gray')}
                  name="responsiblePosition"
                  Component={Input}
                  placeholder={`Insira o Cargo do Responsável`}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid className={classes.textField}>
              <Field
                label={TextPrimary('Nome da organização', 13.5, 'gray')}
                name="organizationName"
                Component={Input}
                fullWidth
                placeholder={`Insira o Nome da organização`}
              />
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ marginBottom: 2, alignItems: 'end' }}
            >
              <Grid item xs={12} md={6}>
                <Field
                  label={TextPrimary(
                    'Percentual mínimo em atividades remotas para gerar certificado',
                    13.5,
                    'gray'
                  )}
                  name="minimumPercentage"
                  Component={Input}
                  fullWidth
                  placeholder={`50`}
                />
              </Grid>
              {hasPresence && (
                <Grid item xs={12} md={6}>
                  <Field
                    label={TextPrimary(
                      'Percentual mínimo de presenças para gerar certificado',
                      13.5,
                      'gray'
                    )}
                    name="minPresence"
                    Component={Input}
                    fullWidth
                    placeholder={`50`}
                  />
                </Grid>
              )}
            </Grid>
            <Box className={classes.uploadImage}>
              <Field
                label={TextPrimary('Assinatura do Responsável', 13.5, 'gray')}
                name="signature"
                Component={DragDropFileInput}
                permittedFileTypes={fileTypes}
                fileTypesDescription={
                  'Apenas arquivos de imagem serão permitidos. Tamanho máximo recomendado: 8000 x 400px'
                }
                maxSize={10}
              />
            </Box>
            <Box className={classes.uploadImage}>
              <Field
                label={TextPrimary('Logo para o Certificado', 13.5, 'gray')}
                name="logo"
                Component={DragDropFileInput}
                permittedFileTypes={fileTypes}
                fileTypesDescription={
                  'Apenas arquivos de imagem serão permitidos.'
                }
                maxSize={10}
              />
            </Box>
            <Grid container spacing={1} className={classes.gridContainer}>
              <Field
                label={TextPrimary(
                  `Habilitar período do ${i18n.t(
                    'StructuresName.Course.SingularLower'
                  )}`
                )}
                name="isCoursePeriod"
                labelPlacement={'end'}
                checked={watch('isCoursePeriod')}
                Component={Switch}
              />
              {isCoursePeriod && (
                <>
                  <Typography
                    variant="body2"
                    className={classes.dataConfigurationTitle}
                  >
                    Período do {i18n.t('Course')}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <DatePickerInputForm
                        placeholder="Ex: 30/04/2020"
                        name="startDate"
                        dataType="início"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DatePickerInputForm
                        placeholder="Ex: 30/04/2020"
                        name="endDate"
                        dataType="término"
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Box className={classes.alertBox}>
              <ErrorOutlineIcon className={classes.alertIcon} />
              <Typography variant="body2" className={classes.alertText}>
                Se o período do {i18n.t('StructuresName.Course.SingularLower')}{' '}
                não for habilitado e definido, o certificado adicionará
                automaticamente o ano da emissão.
              </Typography>
            </Box>
            <Box className={classes.modalActions}>
              <Button onClick={onClose} className={classes.cancelButton}>
                Cancelar
              </Button>
              <Button
                type="submit"
                className={classes.saveButton}
                color="primary"
              >
                {certificateConfig && certificateConfig.id ? 'Salvar' : 'Criar'}
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    </FormProvider>
  );
};

export default CertificateFormModal;

CertificateFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  certificateConfig: PropTypes.shape({
    id: PropTypes.number,
    start_date: PropTypes.instanceOf(Date),
    end_date: PropTypes.instanceOf(Date),
    responsible_for_signing: PropTypes.string,
    signature_owner_position: PropTypes.string,
    signature_owner_organization: PropTypes.string,
    minimum_rate_for_completion: PropTypes.number,
    min_presence: PropTypes.number,
    is_period: PropTypes.bool
  }),
  setCertificateConfig: PropTypes.func.isRequired,
  ID: PropTypes.number.isRequired,
  workload: PropTypes.number,
  createCertificateConfiguration: PropTypes.func.isRequired,
  hasPresence: PropTypes.bool.isRequired
};

CertificateFormModal.defaultProps = {
  certificateConfig: null,
  workload: 0,
  hasPresence: false
};
