import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    backgroundColor: 'white',
    width: '95%',
    maxWidth: '500px',
    borderRadius: '8px',
    padding: '24px 24px 32px 24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    boxShadow: '10px 10px 24px 0px rgba(103, 109, 130, 0.12)'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  titleText: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    color: theme.palette.activeText.main
  },
  warningIcon: {
    width: '32px',
    height: '32px',
    color: theme.palette.warning.main
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px'
  },
  text: {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.activeText.main
  },
  inputFiled: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  textInput: {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.activeText.main,
    padding: '12px 16px'
  },
  errorMessage: {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.error.main
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px'
  },
  cancelButton: {
    fontSize: '16px',
    color: theme.palette.activeText.main,
    textTransform: 'none',
    padding: '8px 16px'
  },
  confirmButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: '16px',
    textTransform: 'none',
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  }
}));
