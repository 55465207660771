import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: '80px',

    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
      gap: '0'
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '24px'
    }
  },
  perPageContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  perPageText: {
    fontSize: '14px',
    lineHeight: '24px',
    color: theme.palette.activeText.main,
    whiteSpace: 'nowrap'
  },
  select: {
    fontSize: '16px',
    lineHeight: '24px',
    padding: '0',
    width: '100%',
    display: 'flex',
    color: theme.palette.activeText.main
  },
  selectRoot: {
    color: theme.palette.activeText.main,
    padding: '8px 16px',

    '& fieldset': {
      borderColor: '#A8B1CE !important'
    }
  },
  selectIcon: {
    width: '24px',
    height: '24px',
    color: '#A8B1CE !important',
    fill: '#A8B1CE !important'
  },
  menuItem: {
    fontSize: '16px',
    lineHeight: '24px',
    padding: '8px 16px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.activeText.main
  },
  paginationUl: {
    '& svg': {
      width: '24px',
      height: '24px',
      color: theme.palette.primary.main
    },
    '& button': {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 'bold',
      color: theme.palette.primary.main,

      backgroundColor: '#F2F2F7',

      '&:hover': {
        backgroundColor: '#F2F2F7'
      }
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',

      '&:hover': {
        backgroundColor: theme.palette.primary.main
      }
    },
    '& li:first-child button': {
      backgroundColor: 'transparent'
    },
    '& li:last-child button': {
      backgroundColor: 'transparent'
    }
  }
}));
