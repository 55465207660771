import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

import { store } from '~/store';

import PropTypes from 'prop-types';

const RouteWrapper = ({
  component: Component,
  isPrivate,
  pageNotFound,
  alwaysAccess,
  ...rest
}) => {
  const { isLogged } = store.getState().auth;
  const courseIDToken = new URLSearchParams(useLocation().search).get('t');
  const sessionState = new URLSearchParams(useLocation().search).get(
    'session_state'
  );
  const code = new URLSearchParams(useLocation().search).get('code');

  const pathnameRestLocation = rest?.location?.pathname;
  const searchRestLocation = rest?.location?.search;

  const checkCourseAndSearch = searchRestLocation && !!courseIDToken;
  const checkSearchAndNoCourseIdToken = searchRestLocation && !courseIDToken;
  const checkSessionCodeTrue = !!sessionState && !!code;
  const checkSessionCodeFalse = !sessionState && !code;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (pathnameRestLocation === '/' || pathnameRestLocation === '/app') {
          if (searchRestLocation && !isLogged && !sessionState) {
            return (
              <Redirect
                to={{
                  pathname: `/login/${rest?.location?.search}`,
                  state: { from: rest?.location }
                }}
              />
            );
          }

          if (checkSearchAndNoCourseIdToken && checkSessionCodeTrue) {
            return (
              <Redirect
                to={{
                  pathname: `/sebrae-login${rest?.location?.search}`,
                  state: { from: rest?.location }
                }}
              />
            );
          }
          if (checkCourseAndSearch && checkSessionCodeTrue) {
            return (
              <Redirect
                to={{
                  pathname: `/sebrae-login${rest?.location?.search}`,
                  state: { from: rest?.location }
                }}
              />
            );
          }
          if (checkCourseAndSearch && checkSessionCodeFalse) {
            return (
              <Redirect
                to={{
                  pathname: `/sebrae-handling`,
                  search: `?t=${courseIDToken}`,
                  state: { from: rest?.location }
                }}
              />
            );
          }
          return <Redirect to={`/login`} />;
        }

        if (
          pathnameRestLocation !== '/' &&
          pathnameRestLocation !== '/conecta-mais' &&
          pathnameRestLocation !== '/app' &&
          pathnameRestLocation !== '/login' &&
          pathnameRestLocation !== '/recover_password' &&
          pathnameRestLocation !== '/about' &&
          pathnameRestLocation !== '/help' &&
          pathnameRestLocation !== '/google_oauth2/callback' &&
          pathnameRestLocation !== '/privacy-policy-and-usage-terms' &&
          pathnameRestLocation?.indexOf('/validate-certificate') === -1 &&
          pathnameRestLocation?.indexOf('/password_reset') === -1 &&
          rest?.location?.search?.indexOf('?callbackUrl') === -1 &&
          !isLogged
        ) {
          return (
            <Redirect
              to={{
                pathname: `/login`,
                search: `?callbackUrl=${rest?.location?.pathname}`
              }}
            />
          );
        }

        if (!isLogged && isPrivate && !pageNotFound) {
          return (
            <Redirect to={{ pathname: '/', state: { from: rest?.location } }} />
          );
        }

        if (isLogged && !alwaysAccess && !isPrivate && !pageNotFound) {
          if (courseIDToken) {
            return (
              <Redirect
                to={{
                  pathname: `/sebrae-handling?t=${courseIDToken}`,
                  state: { from: rest?.location }
                }}
              />
            );
          }

          return <Redirect to="/dashboard" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default RouteWrapper;

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  pageNotFound: PropTypes.bool,
  alwaysAccess: PropTypes.bool
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  pageNotFound: false,
  alwaysAccess: false
};
