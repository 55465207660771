/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import ReactLoading from 'react-loading';
import { useParams, useHistory } from 'react-router-dom';

import DeleteConfirmModal from '~/components/molecules/DeleteConfirmModal';
import { AttendanceManager } from '~/components/organisms/AttendanceManager';
import CourseAdvancedOptions from '~/components/organisms/CourseAdvancedOptions';
import CourseForm from '~/components/organisms/CourseForm';
import CourseReports from '~/components/organisms/CourseReports';
import GenericFormWrapper from '~/components/organisms/GenericFormWrapper';
import MembersManagement from '~/components/organisms/MembersManagement';
import StepperForm from '~/components/templates/StepperForm';
import i18n from '~/I18n';
import attendanceService from '~/services/attendance';
import courseServices, {
  createAttendance,
  getAttendances,
  getEnrollments,
  getPresences
} from '~/services/course';
import { getAttendancesReport } from '~/services/reports/courses';
import { getUserFilterReport } from '~/services/reports/courses';
import userCourseAssociationServices from '~/services/user-course-association';
import { handleSuccess } from '~/utils/auxiliaryFunctions';
import { courseValidations } from '~/utils/CourseValidation/course';
import { formatStringToDate } from '~/utils/dates';

import { getCourse, updateCourse, deleteLimit, deleteCourse } from './fetch';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

export default function EditCourse() {
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { courseID, environmentID } = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);
  const [attendanceIsDisabled, setAttendanceIsDisabled] = useState(false);

  const [, setSubmissionStatus] = useState('');
  const [reportURLs, setReportURLs] = useState('');
  const [mirrorsCount, setMirrorsCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState({
    name: null,
    initials: null,
    id: null,
    description: null,
    key: null,
    id_limit: null,
    user_limit_enabled: null,
    user_limit: null,
    workload: null,
    keywords: [],
    degree_ids: [],
    certificate_configuration_id: null,
    monetized_through_pagseguro: false,
    pagseguro_amount: null,
    current_attendable: null
  });

  const [navOptions, setNavOptions] = useState([
    i18n.t('Information'),
    i18n.t('Members'),
    i18n.t('Reports'),
    i18n.t('AdvancedOptions')
  ]);

  const breadcrumbs = [
    { name: i18n.t('MyEnvironments'), path: '/dashboard/environments' }
  ];

  const handleExclude = () => {
    openDeleteModal();
  };

  const handleDeleteConfirm = () => {
    deleteCourse(courseID)
      .then(() => {
        enqueueSnackbar(
          `${i18n.t(
            'StructuresName.Course.SingularUpper'
          )} excluído com sucesso!`,
          { variant: 'success' }
        );

        history.push(`/dashboard/environments/${environmentID}`);
      })
      .catch(() => {
        enqueueSnackbar(
          `Erro ao excluir ${i18n.t('StructuresName.Course.SingularLower')}.`,
          { variant: 'error' }
        );
      })
      .finally(() => {
        closeDeleteModal();
      });
  };

  const deleteMessage = () => {
    if (mirrorsCount) {
      return (
        <>
          O {i18n.t('StructuresName.Course.SingularLower')}{' '}
          <span style={{ fontWeight: 'bold' }}>{defaultValues.name} </span>
          possui {mirrorsCount} espelho(s) e será permanentemente excluído,
          incluindo todos os dados e arquivos dos seus espelhos. Todos os
          membros irão perder o acesso.
        </>
      );
    } else {
      return (
        <>
          O {i18n.t('StructuresName.Course.SingularLower')}{' '}
          <span style={{ fontWeight: 'bold' }}>{defaultValues.name}</span>, será
          permanentemente excluído, incluindo todos os dados e arquivos. Todos
          os membros irão perder o acesso.
        </>
      );
    }
  };

  function renderContent(props) {
    const { selectedTab } = props;

    if (selectedTab === 'information') {
      return (
        <GenericFormWrapper
          Form={CourseForm}
          defaultValues={defaultValues}
          resolver={courseValidations}
          handleExclude={handleExclude}
          excludeTitle="Excluir"
          isSubmitting={isSubmitting}
          {...props}
        />
      );
    } else if (selectedTab === 'reports') {
      return <CourseReports id={defaultValues.id} />;
    } else if (selectedTab === 'members') {
      return (
        <MembersManagement
          structureID={courseID}
          structureServices={courseServices}
          userStructureAssociationServices={userCourseAssociationServices}
          environmentId={environmentID}
          reportURLs={reportURLs}
          getUserFilterReport={getUserFilterReport}
        />
      );
    } else if (selectedTab === 'advancedOptions') {
      return <CourseAdvancedOptions />;
    } else if (selectedTab === 'attendance') {
      return (
        <AttendanceManager
          structureID={courseID}
          isDisabled={attendanceIsDisabled}
          service={{
            getUsers: getEnrollments,
            getAttendances,
            createAttendance,
            getPresences,
            getAttendancesReport,
            ...attendanceService
          }}
        />
      );
    }

    return false;
  }

  const configCourse = (data) => {
    const formattedDegrees = [];
    data.degree_ids.map((obj) => {
      formattedDegrees[obj.id] = true;
      return obj;
    });

    setReportURLs(data.report_urls);
    if (!data.current_attendable || data.current_attendable === 'Course') {
      setAttendanceIsDisabled(!data.current_attendable);
      setNavOptions((prev) => [
        ...prev.slice(0, 2),
        'Frequência',
        ...prev.slice(2)
      ]);
    }
    setDefaultValues({
      name: data.name,
      id: data.id,
      initials: data.initials,
      description: data.description,
      key: data.subscription?.key,
      id_limit: data.enrollment_limit?.id,
      user_limit_enabled: !!data.enrollment_limit?.user_limit,
      user_limit: data.enrollment_limit?.user_limit,
      workload: data.workload,
      keywords: data.tags,
      degree_ids: formattedDegrees,
      certificate_configuration_id: data.certificate_configuration_id,
      monetized_through_pagseguro: data.monetized_through_pagseguro,
      pagseguro_amount: data.pagseguro_amount,
      current_attendable: data.current_attendable
    });

    setMirrorsCount(data.mirrors_count);

    setIsLoading(false);
  };

  const fetchFailure = () => {
    setIsLoading(false);
    window.alert(
      `Não foi possível carregar o ${i18n.t(
        'StructuresName.Course.SingularUpper'
      )}!`
    );
    handleCancel();
  };

  useEffect(() => {
    if (courseID) {
      setIsLoading(true);
      getCourse(courseID)
        .then(configCourse)
        .catch(fetchFailure);
    }
  }, [courseID]);

  function handleCancel() {
    history.push(
      `/dashboard/environments/${environmentID}/courses/${courseID}`
    );
  }

  const finalizeSuccess = () => {
    setSubmissionStatus('success');
    handleSuccess(
      `O ${i18n.t('StructuresName.Course.SingularLower')} foi atualizado!`,
      enqueueSnackbar,
      `/dashboard/environments/${environmentID}/courses/${courseID}`,
      history
    );
  };

  const finalizeFailure = (error) => {
    // handleErrors(error, enqueueSnackbar);
    enqueueSnackbar('Não foi possível atualizar os dados do curso', {
      variant: 'error'
    });
    setSubmissionStatus('error');
  };

  function submitForm(fields) {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const formData = new FormData();

    let formattedTagList = '';
    let userlimit;
    let formattedDegrees = [];

    if (fields.keywords && fields.keywords.length) {
      formattedTagList = fields.keywords
        .map((keyword) => keyword.name)
        .join(',');
    }

    delete fields.keywords;

    if (fields.user_limit_enabled === false && fields.id_limit) {
      deleteLimit(fields.id_limit);
      delete fields.id_limit;
      delete fields.user_limit;
    }

    if (fields.user_limit) {
      userlimit = {
        user_limit: fields.user_limit
      };
    }

    if (
      fields.profile_attributes &&
      typeof fields.profile_attributes === 'object'
    ) {
      formData.append(
        'course[profile_attributes][image]',
        fields.profile_attributes
      );
    }

    if (fields.cape_attributes && typeof fields.cape_attributes === 'object') {
      formData.append('course[cape_attributes][image]', fields.cape_attributes);
    }

    delete fields.profile_attributes;
    delete fields.cape_attributes;

    if (fields.degree_ids) {
      formattedDegrees = fields.degree_ids
        .map((degreeID, index) => (degreeID ? index.toString() : null))
        .filter((elt) => elt != null);
    }

    delete fields.degree_ids;

    let newDegree = '[';
    formattedDegrees.forEach((degreeId, index) => {
      if (index === formattedDegrees.length - 1) {
        newDegree += `${degreeId}`;
      } else {
        newDegree += `${degreeId},`;
      }
    });
    newDegree += ']';
    formData.append(`course[degree_ids]`, newDegree);

    // Append fields to the FormData object
    for (const key in fields) {
      // eslint-disable-next-line no-prototype-builtins
      if (fields.hasOwnProperty(key)) {
        formData.append(`course[${key}]`, fields[key]);
      }
    }

    formData.append('course[tag_list]', formattedTagList);
    formData.append('course[subscription_type]', fields.subscription_type * 1);

    if (fields.user_limit_enabled) {
      formData.append(
        'course[enrollment_limit_attributes][user_limit]',
        userlimit ? userlimit.user_limit : ''
      );
    }

    formData.append('course[published]', 1);

    updateCourse(courseID, formData)
      .then(finalizeSuccess)
      .catch(finalizeFailure)
      .finally(() => setIsSubmitting(false));
  }

  useEffect(() => {
    document.title = `${theme.title} | Editar ${i18n.t(
      'StructuresName.Course.SingularLower'
    )}`;
  }, []);

  return isLoading ? (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: '100%' }}
    >
      <Grid item>
        <ReactLoading type="cylon" color={theme.palette.primary.main} />
      </Grid>
    </Grid>
  ) : (
    <>
      <DeleteConfirmModal
        show={showDeleteModal}
        handleClose={closeDeleteModal}
        handleConfirm={handleDeleteConfirm}
        maxWidth={440}
        loading={false}
        showButtons={true}
        descriptionMessage={deleteMessage()}
        title={i18n.t('DeleteCourse.title')}
      />
      <StepperForm
        unclickable={false}
        breadcrumbs={breadcrumbs}
        onSubmit={submitForm}
        formTitle={i18n.t('EditMirroredCourse.title')}
        submitButtonTitle={i18n.t('EditMirroredCourse.saveButton')}
        handleCancel={handleCancel}
        navOptions={navOptions}
        renderContent={renderContent}
      />
    </>
  );
}
