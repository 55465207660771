import * as yup from 'yup';

const enrollmentLink = {
  key: yup
    .string()
    .nullable()
    .test(
      'no-whitespace',
      'Não é permitido espaços em branco',
      (value) => !value || !/\s/.test(value)
    )
};

export const enrollmentLinkSchema = yup.object(enrollmentLink);
