import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import settings from '~/settings';

import { useStyles } from './styles';
import { Avatar, Tooltip, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

export const UserCell = ({ user }) => {
  const classes = useStyles();
  const theme = useTheme();

  const textRef = useRef(null);
  const [isEllipsized, setIsEllipsized] = useState(false);

  useEffect(() => {
    const checkEllipsis = () => {
      if (textRef.current) {
        const element = textRef.current;
        setIsEllipsized(element.scrollWidth > element.clientWidth);
      }
    };

    checkEllipsis();

    window.addEventListener('resize', checkEllipsis);

    return () => {
      window.removeEventListener('resize', checkEllipsis);
    };
  }, [user.name]);

  return (
    <Link to={`/profile/${user.id}`} className={classes.root}>
      <Avatar
        src={settings.baseUrl + user.avatar}
        style={{
          color: theme.palette.primary.main,
          width: 20,
          height: 20,
          backgroundColor: theme.palette.common.white
        }}
      />
      <Tooltip
        disableHoverListener={!isEllipsized}
        title={user.name}
        arrow
        placement="top"
        classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
      >
        <Typography ref={textRef} className={classes.name}>
          {user.name}
        </Typography>
      </Tooltip>
    </Link>
  );
};

UserCell.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired
  }).isRequired
};
