import React, { useEffect, useState } from 'react';

import { MarkAttendanceModal } from '~/components/organisms/MarkAttendanceModal';
import { eventEmitter } from '~/services/eventEmitter';
import { markAsDone } from '~/services/Lecture';

import { useStyles } from './styles';
import {
  ButtonBase,
  Checkbox,
  CircularProgress,
  Typography,
  useTheme
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

export const MarkAsDoneButton = ({ lecture }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [done, setDone] = useState(lecture.user_completion);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [openAttendanceModal, setOpenAttendanceModal] = useState(false);

  const handleOpenAttendanceModal = () => {
    if (lecture.attendance && lecture.presence_status !== 'present') {
      setOpenAttendanceModal(
        () =>
          new Date() >= new Date(lecture.attendance.date) &&
          new Date() <= new Date(lecture.attendance.lock_self_presence_at)
      );
    }
  };

  const canMarkAsDone = () => {
    if (
      (lecture.type === 'Exercise' || lecture.type === 'Form') &&
      !lecture.user_completion
    ) {
      enqueueSnackbar('Você precisa responder a atividade para finalizá-la.', {
        variant: 'error'
      });
      return false;
    }
    return true;
  };

  const handleMarkAsDone = async () => {
    if (loading || !canMarkAsDone()) return;
    try {
      setLoading(true);
      await markAsDone(lecture.id);
      setDone(true);
      handleOpenAttendanceModal();
    } catch (e) {
      enqueueSnackbar(
        'Não foi possível processar sua solicitação. Se o erro persistir, entre em contato com a equipe de suporte.',
        { variant: 'error' }
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (lecture.user_completion) handleOpenAttendanceModal();
    if (
      lecture.type !== 'Exercise' &&
      lecture.type !== 'Form' &&
      lecture.markable &&
      !lecture.user_completion &&
      !lecture.can_manage
    ) {
      handleMarkAsDone();
    }
  }, [lecture]);

  useEffect(() => {
    const handleUpdate = ({ markAsDone }) => {
      setDone(markAsDone);
      handleOpenAttendanceModal();
    };

    eventEmitter.on('markAsDoneLectureUpdated', handleUpdate);

    return () => eventEmitter.off('markAsDoneLectureUpdated', handleUpdate);
  }, []);

  return (
    <>
      <ButtonBase
        disableRipple
        disabled={done || loading}
        className={`${classes.button} ${done ? classes.checked : ''}`}
        onClick={handleMarkAsDone}
      >
        {loading ? (
          <CircularProgress size={16} />
        ) : (
          <Checkbox
            checked={done}
            className={classes.checkboxInput}
            icon={
              <CheckBoxOutlineBlankIcon
                style={{
                  color: theme.palette.primary.main
                }}
                className={classes.checkboxIcon}
              />
            }
            checkedIcon={
              <CheckBoxIcon
                style={{
                  color: '#008A00'
                }}
                className={classes.checkboxIcon}
              />
            }
          />
        )}
        <Typography
          className={classes.buttonText}
          style={{
            color: done ? '#008A00' : theme.palette.primary.main
          }}
        >
          {done ? 'Finalizada' : 'Finalizar'}
        </Typography>
      </ButtonBase>
      <MarkAttendanceModal
        open={openAttendanceModal}
        onClose={() => setOpenAttendanceModal(false)}
        presence={{ ...lecture.presence }}
      />
    </>
  );
};

MarkAsDoneButton.propTypes = {
  lecture: PropTypes.shape({
    id: PropTypes.string.isRequired,
    user_completion: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    markable: PropTypes.bool.isRequired,
    can_manage: PropTypes.bool.isRequired,
    attendance: PropTypes.shape(),
    presence_status: PropTypes.bool
  }).isRequired
};
