import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  summary: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '24px',
    color: theme.palette.activeText.main
  },
  description: {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.activeText.main
  }
}));
