import React from 'react';

import DatePicker from '~/components/atoms/DatePicker';

import { useStyles } from './styles';
import { Box, TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';

export const TableHeaderContent = ({ dateFilter, setDateFilter }) => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell align={'left'} className={classes.dateCell}>
        Data
      </TableCell>
      <TableCell align={'left'} className={classes.attendanceCell}>
        Registros de Frequência
      </TableCell>
      <TableCell align={'right'} className={classes.dateFilterCell}>
        <Box className={classes.dateFilterContainer}>
          <DatePicker
            placeholder={'Filtrar por data'}
            input={{
              onChange: setDateFilter,
              value: dateFilter
            }}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

TableHeaderContent.propTypes = {
  dateFilter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  setDateFilter: PropTypes.func.isRequired
};
