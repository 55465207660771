import { isValidCPF } from '~/utils/cpfValidator';

import * as yup from 'yup';

const editMemberValidation = {
  login: yup.string().required('Campo obrigatório'),
  first_name: yup.string().required('Campo obrigatório'),
  last_name: yup.string().required('Campo obrigatório'),
  password: yup.string().min(6, 'A senha deve ter no mínimo 6 caracteres'),
  confirmPassword: yup.string().when('password', {
    is: (password) => password && password.length > 0, // Condição: se password não estiver vazio
    then: yup
      .string()
      .required('Campo obrigatório')
      .oneOf([yup.ref('password'), null], 'As senhas devem coincidir'),
    otherwise: yup.string().notRequired() // Se password não for preenchido, confirmPassword não é obrigatório
  }),
  email: yup
    .string()
    .required('Campo obrigatório')
    .email('Email inválido')
    // eslint-disable-next-line no-useless-escape
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, { message: 'Email inválido' }),
  cpf: yup
    .string()
    .nullable()
    .test(
      'is-valid-cpf',
      'CPF inválido.',
      (value) => isValidCPF(value || '') || !value
    )
};

export const editMemberScheme = yup.object(editMemberValidation);
