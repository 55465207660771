import api from '~/services/api';

const baseURL = '/api/courses';

const getUserPerformanceReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/users_performance_report`, {
    responseType: 'blob'
  });
  return data;
};

const getConclusionReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/conclusion_space_report`, {
    responseType: 'blob'
  });
  return data;
};

const getCourseInformationReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/information_course_report`, {
    responseType: 'blob'
  });
  return data;
};

const getTypeUsersReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/user_registered_report`, {
    responseType: 'blob'
  });
  return data;
};

const getUserListReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/user_list_report`, {
    responseType: 'blob'
  });
  return data;
};

const getSimplifiedReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/simplified_report`, {
    responseType: 'blob'
  });
  return data;
};

const getUserFilterReport = async (structureID, usersID) => {
  const { data } = await api.get(
    `${baseURL}/${structureID}/user_filter_report`,
    {
      responseType: 'blob',
      params: { users: usersID }
    }
  );
  return data;
};

const getAttendancesReport = async (courseID) => {
  const { data } = await api.get(`${baseURL}/${courseID}/attendances/report`);
  return data;
};

export {
  getUserPerformanceReport,
  getConclusionReport,
  getCourseInformationReport,
  getTypeUsersReport,
  getUserListReport,
  getSimplifiedReport,
  getUserFilterReport,
  getAttendancesReport
};
