import React from 'react';

import { ReactComponent as DocumentsCuateImage } from '~/assets/images/documents-cuate.svg';
import GenericFeedbackLayout from '~/components/molecules/GenericFeedbackLayout';
import { DataDisplay } from '~/components/organisms/AttendanceManager/DataDisplay';
import { SubHeader } from '~/components/organisms/AttendanceManager/SubHeader';
import { AttendanceContextProvider } from '~/contexts/AttendanceManager';

import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

export const AttendanceManager = ({ service, structureID, isDisabled }) => {
  const classes = useStyles();

  if (isDisabled) {
    return (
      <GenericFeedbackLayout
        ImageIcon={DocumentsCuateImage}
        title="Lista de Frequência Desabilitada!"
        description="Habilite o registro de presença na tela de edição do ambiente."
      />
    );
  }

  return (
    <Box className={classes.root}>
      <AttendanceContextProvider service={service} structureID={structureID}>
        <SubHeader />
        <DataDisplay />
      </AttendanceContextProvider>
    </Box>
  );
};

AttendanceManager.propTypes = {
  service: PropTypes.object.isRequired,
  structureID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isDisabled: PropTypes.bool.isRequired
};
