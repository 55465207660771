import api from '~/services/api';

const baseURL = '/api/presences';

export const update = (presenceID, status = 'present') => {
  return api.put(`${baseURL}/${presenceID}`, {
    presence: { status }
  });
};

export default {
  update
};
