import React, { useState } from 'react';

import Switch from '~/components/atoms/Switch';
import enrollmentLinkService from '~/services/enrollment-link';

import EnrollmentLinkForm from '../EnrollmentLinkForm';
import { useStyles } from './styles';
import { Box, Collapse, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';

export default function EnrollmentLinkToggle({
  structureID,
  tooltipMessage,
  getEnrollmentLink,
  createEnrollmentLink
}) {
  const classes = useStyles();
  const [show, setShow] = useState(false);

  return (
    <>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent="space-between"
      >
        <Typography className={classes.title}>
          Configurar Link de Inscrição
        </Typography>
        <Switch checked={show} onChange={() => setShow((prev) => !prev)} />
      </Box>

      <Collapse in={show}>
        {show && structureID && (
          <EnrollmentLinkForm
            structureID={structureID}
            tooltipMessage={tooltipMessage}
            get={getEnrollmentLink}
            create={createEnrollmentLink}
            enrollmentLinkService={enrollmentLinkService}
          />
        )}
      </Collapse>
    </>
  );
}

EnrollmentLinkToggle.propTypes = {
  structureID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  getEnrollmentLink: PropTypes.func,
  createEnrollmentLink: PropTypes.func,
  enrollmentLinkService: PropTypes.shape({
    update: PropTypes.func,
    remove: PropTypes.func
  }),
  tooltipMessage: PropTypes.string
};

EnrollmentLinkToggle.defaultProps = {
  structureID: undefined,
  getEnrollmentLink: async () => {},
  createEnrollmentLink: async () => {},
  enrollmentLinkService: PropTypes.shape({
    update: async () => {},
    remove: async () => {}
  }),
  tooltipMessage: ''
};
