import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import DateTimePickerInput from '~/components/atoms/DateTimePickerInput';
import { eventEmitter } from '~/services/eventEmitter';
import { editLectureAttendanceSchema } from '~/utils/Validations/attendanceManager';

import { useStyles } from './styles';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';

export const EditLectureAttendanceForm = ({
  onCloseModal,
  initialValues,
  onSubmit
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      duration: initialValues?.duration || '',
      date: initialValues?.date ? new Date(initialValues.date) : new Date(),
      lock_self_presence_at: initialValues?.lock_self_presence_at
        ? new Date(initialValues.lock_self_presence_at)
        : new Date()
    },
    resolver: yupResolver(editLectureAttendanceSchema)
  });

  const submit = async (data) => {
    if (loading) return;
    setLoading(true);
    onSubmit && (await onSubmit({ attendance: { ...data } }));
    setLoading(false);
    eventEmitter.emit('resetAttendancesData');
    onCloseModal && onCloseModal();
  };

  return (
    <Box
      component="form"
      className={classes.form}
      onSubmit={handleSubmit(submit)}
    >
      <Box>
        <Typography className={classes.label}>Carga horária</Typography>
        <TextField
          {...register('duration')}
          variant="outlined"
          placeholder="Escreva a carga horária aqui..."
          hiddenLabel
          fullWidth
          inputProps={{
            className: classes.textInput
          }}
          className={classes.textfield}
        />
        {errors.duration && (
          <Typography className={classes.errorMessage}>
            {errors.duration.message}
          </Typography>
        )}
      </Box>
      <Box className={classes.dateContainer}>
        <Typography className={classes.LabelAccent}>
          Prazo disponível para registrar frequência
        </Typography>
        <Box className={classes.dateInputContainer}>
          <Box>
            <Typography className={classes.label}>
              Data inicial do registro de frequência:
            </Typography>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <DateTimePickerInput
                  {...field}
                  onChange={(value) => setValue('date', value)}
                  value={field.value}
                />
              )}
            />
            {errors.date && (
              <Typography className={classes.errorMessage}>
                {errors.date.message}
              </Typography>
            )}
          </Box>
          <Box>
            <Typography className={classes.label}>
              Data limite do registro de frequência:
            </Typography>
            <Controller
              name="lock_self_presence_at"
              control={control}
              render={({ field }) => (
                <DateTimePickerInput
                  {...field}
                  onChange={(value) => setValue('lock_self_presence_at', value)}
                  value={field.value}
                />
              )}
            />
            {errors.lock_self_presence_at && (
              <Typography className={classes.errorMessage}>
                {errors.lock_self_presence_at.message}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Box className={classes.actionButtons}>
        <Button
          type="reset"
          className={classes.cancelButton}
          variant="text"
          onClick={onCloseModal}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="contained"
          className={classes.confirmButton}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Salvar'}
        </Button>
      </Box>
    </Box>
  );
};

EditLectureAttendanceForm.propTypes = {
  onCloseModal: PropTypes.func,
  initialValues: PropTypes.shape({
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    lock_self_presence_at: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ])
  }),
  onSubmit: PropTypes.func.isRequired
};
