import React, { useEffect, useRef, useState } from 'react';
import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Element } from 'react-scroll';

import AvatarEditor from '~/components/atoms/AvatarEditor';
import Text from '~/components/atoms/Text';
import LeftDrawer from '~/components/Badges/Drawer/LeftDrawer';
import AccountEdit from '~/components/molecules/AccountEdit';
import AppBarCustom from '~/components/molecules/AppBar';
import EditUsername from '~/components/molecules/EditUsername';
import EditBioCurriculum from '~/components/organisms/EditBioCurriculum';
import SideNav from '~/components/templates/SideNav';
import {
  educationableTypes,
  higherEducationKind,
  eventEducationRole
} from '~/constants/education';
import { Months } from '~/constants/months';
import i18n from '~/I18n';
import api from '~/services/api';
import history from '~/services/history';
import { Creators as UserCreators } from '~/store/ducks/user';
import { validateNameAndLogin } from '~/utils/EditProfileValidation';

import { LinkItem, useStyles } from './styles';
import {
  Box,
  ListItem,
  List,
  Grid,
  useTheme,
  Tabs,
  Tab
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { parsePhoneNumber } from 'libphonenumber-js';

const EditProfile = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { setUsernameAndLogin, getProfileRequest } = UserCreators;

  const { id } = useParams();
  const { profile } = useSelector((state) => state.user);

  const [open, setOpen] = useState(false);
  const [showUsernameModal, setShowUsernameModal] = useState(false);
  const [usernameErrors, setUsernameErrors] = useState({});
  const [image, setImage] = useState(null);
  const [showImageSizeWarningText, setShowImageSizeWarningText] = useState(
    false
  );
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [submittingBC, setSubmittingBC] = useState(false);
  const [bioAndCurriculum, setBioAndCurriculum] = useState(true);
  const [settingsAccount, setSettingsAccount] = useState(false);
  const [bioAndCurriculumColor, setBioAndCurriculumColor] = useState(
    theme.palette.primary.main
  );
  const [settingsAccountColor, setSettingsAccountColor] = useState('gray');

  const fillingError = useRef(false);

  const {
    root,
    content,
    rightContent,
    menuItem,
    menu,
    contentContainer,
    sidenavContainer,
    editPhotoDesktop,
    editPhotoTabletMobile
  } = useStyles();

  const goToBioAndCurriculum = () => {
    setBioAndCurriculum(true);
    setSettingsAccount(false);
    setBioAndCurriculumColor(theme.palette.primary.main);
    setSettingsAccountColor('gray');
  };

  const goToSettingAccount = () => {
    setSettingsAccount(true);
    setBioAndCurriculum(false);
    setSettingsAccountColor(theme.palette.primary.main);
    setBioAndCurriculumColor('gray');
  };

  useEffect(() => {
    // setLoggedInUser(profile?.id === id);
    if (profile) {
      if (profile.id.toString() !== id) {
        history.push('/page-not-found');
      }
    }
  }, [profile]);

  const clearUsernameErrors = () => {
    setUsernameErrors({});
  };

  const submitUsername = (name, login) => {
    // fazer validacao
    const errors = validateNameAndLogin(name, login);
    setUsernameErrors(errors);

    // se nao ocorreram erros na checagem do front, tentar subir pra a api
    if (Object.keys(errors).length === 0) {
      const firstName = name === '' ? '' : name.substr(0, name.indexOf(' '));
      const lastName = name === '' ? '' : name.substr(name.indexOf(' ') + 1);

      api
        .put(`/api/users/${profile?.id}`, {
          user: {
            first_name: firstName,
            last_name: lastName,
            login
          }
        })
        .then(() => {
          // se der certo, fecho modal e salvo no redux
          setShowUsernameModal(false);
          dispatch(setUsernameAndLogin(firstName, lastName, login));
        })
        .catch(() => {
          // se der errado, é porque o login ja existe, entao atualizo o estado de erro
          setUsernameErrors({ ...errors, loginError: 'Este login já existe.' });
        });
    }
  };

  const formatEducations = (educations) => {
    if (!educations) {
      return;
    }

    let formattedEducations = [...educations];

    formattedEducations = formattedEducations.map((edu) => {
      const newEdu = { ...edu };
      const newEducationable = { ...edu.educationable };

      const newEduType = educationableTypes.find(
        (type) => type.name === edu.educationable_type
      );

      newEdu.educationable_type = newEduType ? newEduType.id : '';

      if (edu.educationable.kind) {
        const kind = higherEducationKind.find(
          (kind) => kind.name === edu.educationable.kind
        );
        newEducationable.kind = kind ? kind.id : '';
      }

      if (edu.educationable.role) {
        const role = eventEducationRole.find(
          (role) => role.name === edu.educationable.role
        );
        newEducationable.role = role ? role.id : '';
      }

      if (edu.educationable.year) {
        newEducationable.year = `${edu.educationable.year}-01-01`;
      }
      if (edu.educationable.start_year) {
        newEducationable.start_year = `${edu.educationable.start_year}-01-01`;
      }
      if (edu.educationable.end_year) {
        newEducationable.end_year = `${edu.educationable.end_year}-01-01`;
      }

      newEdu.educationable = newEducationable;
      return newEdu;
    });

    return formattedEducations;
  };

  const formatExperiences = (experiences) => {
    if (!experiences) {
      return;
    }

    let formattedExperiences = [...experiences];
    formattedExperiences = formattedExperiences.map((exp) => {
      let startMonth = Months.findIndex((m) => m == exp.start_date.month) + 1;
      let endMonth = exp.end_date
        ? Months.findIndex((m) => m == exp.end_date.month) + 1
        : '';

      if (startMonth < 10) startMonth = `0${startMonth}`;
      if (endMonth != '' && endMonth < 10) endMonth = `0${endMonth}`;

      const startDate = `${exp.start_date.year.toString()}-${startMonth}-01`;
      const endDate = exp.current
        ? ''
        : `${exp.end_date.year.toString()}-${endMonth}-01`;

      const newExp = { ...exp };

      newExp.start_date = startDate;
      if (!exp.current) {
        newExp.end_date = endDate;
        newExp.current = false;
      } else if (exp.end_date) delete newExp.end_date;

      return newExp;
    });

    return formattedExperiences;
  };

  const birthday = useMemo(() => {
    const data = profile?.birthday?.split('-');
    if (data) {
      const month = Months[parseInt(data[1]) - 1];

      return { day: data[2], month: month, year: data[0] };
    }
  }, [profile]);

  const phone = useMemo(() => {
    if (profile?.mobile) {
      const phone = profile.mobile.replace(')', '').replace('(', '');

      return parsePhoneNumber(phone, 'BR').nationalNumber;
    }

    return '';
  }, [profile]);

  // submit form data
  const submitBioCurriculum = (values) => {
    if (!values) return;

    setDisplayError(false);
    setDisplaySuccess(false);
    setSubmittingBC(true);

    const formattedEducations = formatEducations(values?.educations);
    const formattedExperiences = formatExperiences(values?.experiences);

    let month = Months.findIndex((m) => m == values.month) + 1;
    let monthFinal = month < 10 ? `0${month}` : month;

    const profileInfo = {
      interested_areas:
        values.interested_areas !== '' ? values.interested_areas : '',
      description: values.description !== '' ? values.description : '',
      mobile: values.mobile !== '' ? `+55 ${values.mobile}` : '',
      languages: values.languages !== '' ? values.languages : '',
      gender: values.gender !== '' ? values.gender : profile.gender,
      birthday:
        values.day !== '' && values.month !== '' && values.year !== ''
          ? `${values.year}-${monthFinal}-${values.day}`
          : profile.birthday,
      cpf: values.cpf ? values.cpf : ''
    };
    profileInfo.educations = formattedEducations ?? profile.educations;
    profileInfo.experiences = formattedExperiences ?? profile.experiences;

    // Caso tenha algum erro de preenchimento em Formação ou Experiência Profissional, libera o erro
    if (fillingError.current) {
      setSubmittingBC(false);
    } else {
      console.log('----------- [  ] -----------');
      console.log({ ...profileInfo });
      console.log('----------- [  ] -----------');
      api
        .put(`/api/users/${profile?.id}`, { user: { ...profileInfo } })
        .then(() => {
          dispatch(getProfileRequest());
          setDisplaySuccess(true);
          setSubmittingBC(false);
        })
        .catch(() => {
          setDisplayError(true);
          setSubmittingBC(false);
        });
    }
  };

  function submitAccountEdit(values) {
    if (!values) return;

    // api
    //   .put(`/api/users/${profile?.id}`, values)
    //   .then((response) => {
    //     dispatch(getProfileRequest());
    //   })
    //   .catch((error) => {
    //   });
  }

  function toggleDrawer() {
    setOpen(!open);
  }

  function handleShowModal() {
    setShowUsernameModal(true);
  }

  useEffect(() => {
    document.title = `${theme.title} | Editar perfil`;
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box className={root}>
        <AppBarCustom profile={profile} setOpen={toggleDrawer} />
        <LeftDrawer open={open} setOpen={toggleDrawer} />
        <Box className={content}>
          <Box className={sidenavContainer}>
            <SideNav color="white">
              <Box className={editPhotoDesktop}>
                <AvatarEditor
                  profile={profile}
                  size={100}
                  image={image}
                  setImage={setImage}
                  setShowImageSizeWarningText={setShowImageSizeWarningText}
                  cropShape={'round'}
                />
                <EditUsername
                  profile={profile}
                  submitUsername={submitUsername}
                  showUsernameModal={showUsernameModal}
                  setShowUsernameModal={setShowUsernameModal}
                  handleShowModal={handleShowModal}
                  errors={usernameErrors}
                  clearErrors={clearUsernameErrors}
                />
                {showImageSizeWarningText && (
                  <Text variant="mediumText" color="error" textAlign="center">
                    {i18n.t('MaximumImageSize')}
                  </Text>
                )}
              </Box>
              <List className={menu}>
                <ListItem className={menuItem}>
                  <LinkItem
                    containerId="scroll-container"
                    style={{ color: bioAndCurriculumColor }}
                    onClick={() => goToBioAndCurriculum()}
                  >
                    {i18n.t('BioCurriculum')}
                  </LinkItem>
                </ListItem>
                <ListItem className={menuItem}>
                  <LinkItem
                    containerId="scroll-container"
                    style={{ color: settingsAccountColor }}
                    onClick={() => goToSettingAccount()}
                  >
                    Configurações da Conta
                  </LinkItem>
                </ListItem>
              </List>
            </SideNav>
          </Box>
          <Box className={rightContent} id="scroll-container">
            <Grid lg={6} md={11} sm={12} className={contentContainer}>
              <Element>
                <Box className={editPhotoTabletMobile}>
                  <AvatarEditor
                    profile={profile}
                    size={100}
                    image={image}
                    setImage={setImage}
                    cropShape={'round'}
                  />
                  <EditUsername
                    profile={profile}
                    submitUsername={submitUsername}
                    showUsernameModal={showUsernameModal}
                    setShowUsernameModal={setShowUsernameModal}
                    handleShowModal={handleShowModal}
                    errors={usernameErrors}
                    clearErrors={clearUsernameErrors}
                  />
                  <Tabs
                    style={{
                      marginTop: '16px',
                      width: '100%'
                    }}
                    value={bioAndCurriculum ? 0 : 1}
                    indicatorColor="primary"
                  >
                    <Tab
                      containerId="scroll-container"
                      style={{
                        color: bioAndCurriculumColor,
                        textTransform: 'none',
                        fontSize: '14px',
                        fontWeight: 400,
                        flex: 1
                      }}
                      disableRipple
                      onClick={() => goToBioAndCurriculum()}
                      label={i18n.t('BioCurriculum')}
                    />
                    <Tab
                      containerId="scroll-container"
                      style={{
                        color: settingsAccountColor,
                        textTransform: 'none',
                        fontSize: '14px',
                        fontWeight: 400,
                        flex: 1
                      }}
                      disableRipple
                      onClick={() => goToSettingAccount()}
                      label=" Configurações da Conta"
                    />
                  </Tabs>
                </Box>
              </Element>
              {bioAndCurriculum && (
                <Element name="bioandcurriculo">
                  <EditBioCurriculum
                    profileData={profile}
                    onSubmit={submitBioCurriculum}
                    displayFillingError={fillingError.current}
                    displayError={displayError}
                    displaySuccess={displaySuccess}
                    setFillingError={(value) => {
                      fillingError.current = value;
                    }}
                    setDisplayError={setDisplayError}
                    setDisplaySuccess={setDisplaySuccess}
                    submittingBC={submittingBC}
                    birthday={birthday}
                    phone={phone}
                  />
                </Element>
              )}
              {settingsAccount && (
                <Element name="accountsettings" id="accountsettings">
                  <AccountEdit onSubmit={submitAccountEdit} />
                </Element>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default EditProfile;
