import React, { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import DateTimePickerInput from '~/components/atoms/DateTimePickerInput';
import Field from '~/components/organisms/GenericFormWrapper/Field';

import { CustomSwitch } from '../CustomSwitch';
import { LabelText } from '../LabelText';
import { useStyles } from './styles';
import { Box, Collapse, Grid, TextField, Typography } from '@material-ui/core';

export const Attendance = () => {
  const classes = useStyles();
  const { control, watch, setValue } = useFormContext();

  useEffect(() => {
    if (watch('attendance.enable')) {
      setValue('attendance.duration', 0);
      setValue('attendance.date', null);
      setValue('attendance.lock_self_presence_at', null);
    }
  }, [watch('attendance.enable')]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box className={classes.fieldBox}>
          <LabelText>Registrar presença através da mídia</LabelText>
          <Controller
            name="attendance.enable"
            control={control}
            render={({ field }) => (
              <CustomSwitch
                size="small"
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.helpText}>
          Se essa opção estiver ativa, a finalização da mídia será contabilizada
          na ata de frequências.{' '}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Collapse in={watch('attendance.enable')}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Field
                label={<LabelText>Carga horária</LabelText>}
                name="attendance.duration"
                control={control}
                Component={(props) => (
                  <TextField
                    {...props}
                    variant="outlined"
                    hiddenLabel
                    fullWidth
                    placeholder="Escreva a carga horária aqui..."
                    type="number"
                    inputProps={{
                      className: classes.workloadInput
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Field
                    label={
                      <LabelText>Início do registro de frequência:</LabelText>
                    }
                    name="attendance.date"
                    control={control}
                    Component={(props) => (
                      <DateTimePickerInput
                        {...props}
                        placeholder="Selecione a data..."
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    label={
                      <LabelText>Término do registro de frequência:</LabelText>
                    }
                    name="attendance.lock_self_presence_at"
                    control={control}
                    Component={(props) => (
                      <DateTimePickerInput
                        {...props}
                        placeholder="Selecione a data..."
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};
