import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    overflow: 'hidden',
    flex: '1 1 auto'
  }
}));
