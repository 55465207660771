import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import NotificationInfo from '~/components/organisms/CourseForm/NotificationInfo';
import PagseguroInfo from '~/components/organisms/CourseForm/PagseguroInfo';
import { createCertificateConfiguration, findByID } from '~/services/course';

import CertificateConfiguration from '../../molecules/CertificateConfiguration';
import EnrollmentLimit from './EnrollmentLimit';
import EnrollmentLink from './EnrollmentLink';
import { useStyles } from './styles';
import { Box, CircularProgress, Divider } from '@material-ui/core';
import { useSnackbar } from 'notistack';

export default function CourseAdvancedOptions() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState({});
  const { courseID } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const getCourse = async () => {
    try {
      setLoading(true);
      const response = await findByID(courseID);
      setCourse(response);
    } catch (error) {
      enqueueSnackbar('Erro ao buscar informações do curso', {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCourse();
  }, [courseID]);

  return (
    <Box className={classes.container}>
      {!loading ? (
        <>
          <Box>
            <EnrollmentLink courseID={courseID} />
            <Divider className={classes.divider} />
          </Box>

          <Box>
            <EnrollmentLimit course={course} />
            <Divider className={classes.divider} />
          </Box>

          <Box marginBottom={2}>
            <PagseguroInfo course={course} />
            <Divider className={classes.divider} />
          </Box>

          <Box>
            <CertificateConfiguration
              structure={course}
              createCertificateConfiguration={createCertificateConfiguration}
              hasPresence={course.current_attendable === 'Course'}
            />
          </Box>

          {/*

      <Box marginBottom={2}>
        <NotificationInfo />
        <Divider className={classes.divider} />
      </Box> */}
        </>
      ) : (
        <CircularProgress size={64} style={{ margin: 'auto' }} />
      )}
    </Box>
  );
}
