import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: '24px',
    borderRadius: '8px',
    boxShadow: '10px 10px 24px 0px rgba(103, 109, 130, 0.12)',
    backgroundColor: theme.palette.background.paper,
    width: '508px',
    maxWidth: '95vw'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  titleIcon: {
    width: '32px',
    height: '32px',
    color: theme.palette.primary.main
  },
  titleText: {
    color: theme.palette.activeText.main,
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '32px'
  },
  closeButton: {
    padding: '4px'
  },
  closeIcon: {
    color: theme.palette.activeText.main,
    width: '32px',
    height: '32px'
  },
  description: {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.activeText.main
  },
  confirmButton: {
    alignSelf: 'flex-end',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: '8px 24px',
    borderRadius: '4px',
    textTransform: 'none',

    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  }
}));
