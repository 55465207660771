import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '24px',
    boxShadow: 24,
    outline: 'none',
    borderRadius: '6px',
    display: 'flex',
    gap: '24px',

    minWidth: '700px',

    [theme.breakpoints.down('sm')]: {
      minWidth: '0',
      maxWidth: '95%',
      width: '95%',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  imageIcon: {
    width: '100%',
    height: 'auto',
    maxWidth: '435px',
    objectFit: 'contain'
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: '1 1 auto',
    gap: '24px',
    minWidth: 'auto'
  },
  title: {
    fontSize: '30px',
    fontWeight: 'bold',
    lineHeight: '36px',
    color: theme.palette.activeText.main
  },
  description: {
    fontSize: '18px',
    lineHeight: '30px',
    color: theme.palette.activeText.main
  }
}));
