import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '500px'
  },
  title: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: '700',
    color: theme.palette.activeText.main
  },
  description: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    color: theme.palette.activeText.main
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  confirmButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: '8px 24px',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '500',
    borderRadius: '4px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  cancelButton: {
    padding: '8px 24px',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '500',
    color: theme.palette.primary.main,
    borderRadius: '4px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  }
}));
