import React, { createContext, useContext, useEffect, useState } from 'react';

import { ReactComponent as MaintenanceImage } from '~/assets/images/maintenance-cuate.svg';
import { StaticFeedbackModal } from '~/components/molecules/StaticFeedbackModal';
import { eventEmitter } from '~/services/eventEmitter';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const attendanceContext = createContext();

export const useAttendanceContext = () => useContext(attendanceContext);

export const AttendanceContextProvider = ({
  children,
  service,
  structureID
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [presences, setPresences] = useState(null);
  const [users, setUsers] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [attendances, setAttendances] = useState([]);
  const [loading, setLoading] = useState(false);

  const [rendering, setRendering] = useState(false);

  const filterUsers = (presencesUsers) => {
    const users = Object.values(presencesUsers).map(
      (presences) => presences[0].user
    );
    return Array.from(new Map(users.map((user) => [user.id, user])).values());
  };

  const getAttendances = async (page, perPage, filters) => {
    try {
      const attendances = await service.getAttendances(
        structureID,
        page,
        perPage,
        filters
      );
      setAttendances(attendances);
    } catch (e) {
      enqueueSnackbar('Erro ao buscar dados sobre a frequência', {
        variant: 'error'
      });
    }
  };

  const forceRerenderingWithSetRenderingState = () => {
    setRendering(true);
    return new Promise((resolve) => {
      setTimeout(() => {
        setRendering(false);
        resolve();
      }, 1);
    });
  };

  const getPresences = async (page = 1, perPage = 50, curFilters = {}) => {
    if (loading) return;
    setLoading(true);
    setPresences(null);
    try {
      const response = await service.getPresences(
        structureID,
        page,
        perPage,
        curFilters
      );
      const presencesUsers = Object.groupBy(
        response.presences || [],
        ({ user }) => user?.id
      );
      const users = filterUsers(presencesUsers);
      response.presences.length > 100 &&
        (await forceRerenderingWithSetRenderingState());
      setPresences(presencesUsers);
      setUsers(users);
      setPaginationData((prev) => ({
        ...prev,
        ...response.pagination,
        page: page,
        perPage: perPage
      }));
    } catch (e) {
      enqueueSnackbar('Erro ao buscar dados sobre a presença dos alunos', {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const resetAttendancesData = async () => {
    setLoading(true);
    await getAttendances();
    await getPresences();
    setLoading(false);
  };

  const updatePresencesWithFilters = async (filters) => {
    if (!filters) return;
    setLoading(true);
    await getAttendances(undefined, undefined, filters);
    await getPresences(1, 50, filters);
    setLoading(false);
  };

  useEffect(() => {
    resetAttendancesData();
    eventEmitter.on('resetAttendancesData', resetAttendancesData);
    return () => {
      eventEmitter.off('resetAttendancesData', resetAttendancesData);
    };
  }, []);

  return (
    <attendanceContext.Provider
      value={{
        attendances,
        presences,
        users,
        getPresences,
        loading,
        paginationData,
        structureID,
        service,
        updatePresencesWithFilters
      }}
    >
      {children}
      <StaticFeedbackModal
        open={rendering}
        title=" Hmm, isso está demorando um pouco mais que o planejado..."
        description={
          <>
            <p style={{ marginBottom: '8px' }}>Por favor, aguarde!</p>
            <p>
              Estamos processando as últimas informações, vamos te avisar se
              algo der errado.
            </p>
          </>
        }
        ImageIcon={MaintenanceImage}
      />
    </attendanceContext.Provider>
  );
};

AttendanceContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  service: PropTypes.shape({
    getAttendances: PropTypes.func.isRequired,
    getPresences: PropTypes.func.isRequired
  }).isRequired,
  structureID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  filters: PropTypes.object
};
