import api from '~/services/api';

const baseURL = '/api/attendances';

export const destroy = (id) => {
  return api.delete(`${baseURL}/${id}`);
};

export const update = (id, data) => {
  return api.put(`${baseURL}/${id}`, data);
};

export default {
  destroy,
  update
};
