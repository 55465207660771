import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    overflow: 'hidden',
    paddingBottom: '16px',
    minHeight: '100%',
    '& *::-webkit-scrollbar': {
      width: '5px',
      height: '5px'
    },
    '& *::-webkit-scrollbar-thumb': {
      background: '#B4BDD3'
    },
    '& *::-webkit-scrollbar-thumb:hover': {
      background: '#A8B1CE'
    }
  }
}));
