import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  root: {
    backgroundColor: 'white',
    padding: '24px',
    borderRadius: '8px',
    boxShadow: '10px 10px 24px 0px rgba(103, 109, 130, 0.12)',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    maxHeight: '95vh',
    maxWidth: '95vw'
  },
  title: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 'bold',
    color: theme.palette.activeText.main
  }
}));
