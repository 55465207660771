import React, { useState } from 'react';

import { useAttendanceContext } from '~/contexts/AttendanceManager';

import { useStyles } from './styles';
import {
  Box,
  ButtonBase,
  CircularProgress,
  Typography
} from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import fileDownload from 'js-file-download';
import { useSnackbar } from 'notistack';

export const ContextualBar = () => {
  const classes = useStyles();
  const [fetchingReport, setFetchingReport] = useState(false);
  const {
    loading,
    paginationData,
    presences,
    service,
    structureID
  } = useAttendanceContext();
  const { enqueueSnackbar } = useSnackbar();

  const handleDownloadReport = async () => {
    if (fetchingReport) return;
    try {
      setFetchingReport(true);
      const report = await service.getAttendancesReport(structureID);
      fileDownload(report, 'relatório_de_presenças.csv');
    } catch (error) {
      enqueueSnackbar('Erro ao baixar relatório', { variant: 'error' });
    }
    setFetchingReport(false);
  };

  if (loading || !presences) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <ButtonBase
        disableRipple
        className={classes.button}
        disabled={fetchingReport}
        onClick={handleDownloadReport}
      >
        {fetchingReport ? (
          <CircularProgress size={16} />
        ) : (
          <GetAppRoundedIcon className={classes.buttonIcon} />
        )}
        <Typography className={classes.buttonText}>Baixar relatório</Typography>
      </ButtonBase>

      <Typography className={classes.itensCountText}>{`${
        paginationData.first_element
      } - ${paginationData.first_element +
        Object.keys(presences).length -
        1} de ${paginationData.total_users}`}</Typography>
    </Box>
  );
};
