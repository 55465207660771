import React from 'react';

import { makeStyles } from '@material-ui/core';
import { Switch, withStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center'
  }
});

export const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 36,
    height: 20,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    '&$checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        borderColor: theme.palette.primary.main
      }
    },
    '&$focusVisible $thumb': {
      color: theme.palette.primary.main,
      border: '6px solid #fff'
    }
  },
  thumb: {
    height: '12px !important',
    width: '12px !important'
  },
  track: {
    borderRadius: 13,
    backgroundColor: '#A8B1CE',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {}
}))((props) => <Switch {...props} size="small" />);
