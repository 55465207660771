import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import Text from '~/components/atoms/Text';
import Input from '~/components/atoms/TextInput';
import i18n from '~/I18n';
import { update as updateCourse } from '~/services/course';

import { useStyles } from './styles';
import {
  Box,
  Button,
  Collapse,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

export default function PagseguroInfo({ course }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, watch, control } = useForm({
    defaultValues: {
      monetized_through_pagseguro: course.monetized_through_pagseguro,
      pagseguro_amount: course.pagseguro_amount
    }
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    if (loading) return;
    try {
      setLoading(true);
      await updateCourse(course.id, {
        monetized_through_pagseguro: true,
        pagseguro_amount: parseFloat(data.pagseguro_amount.replace(',', '.'))
      });
      enqueueSnackbar('Monetização ativada com sucesso.', {
        variant: 'success'
      });
    } catch {
      enqueueSnackbar(
        'Não foi possível habilitar a monetização, tente novamente mais tarde.',
        { variant: 'error' }
      );
    } finally {
      setLoading(false);
    }
  };

  const disablePagSeguroMonetized = async (value, onChange) => {
    if (loading) return;
    try {
      setLoading(true);
      await updateCourse(course.id, {
        monetized_through_pagseguro: false,
        pagseguro_amount: ''
      });
      onChange && onChange(value);
    } catch {
      enqueueSnackbar(
        'Não foi possível desabilitar a monetização, tente novamente mais tarde.',
        { variant: 'error' }
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Controller
        name="monetized_through_pagseguro"
        control={control}
        defaultValue={course.monetized_through_pagseguro}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Switch
                disabled={loading}
                checked={field.value}
                onChange={(e) => {
                  if (!e.target.checked)
                    disablePagSeguroMonetized(e.target.checked, field.onChange);
                  field.onChange(e.target.checked);
                }}
                color="primary"
              />
            }
            label={
              <Text color={'gray'}>
                {`Monetizar ${i18n.t('StructuresName.Course.SingularLower')}`}
              </Text>
            }
          />
        )}
      />

      <Collapse in={watch('monetized_through_pagseguro')}>
        <Box className={classes.container}>
          <Controller
            name="pagseguro_amount"
            control={control}
            defaultValue={course.pagseguro_amount}
            rules={{
              required: 'O preço é obrigatório',
              validate: (value) => {
                if (!value) return 'O preço é obrigatório';
                if (!/^\d+(,\d{1,2})?$/.test(value))
                  return 'Formato inválido. Ex: 35,99';
                return true;
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControlLabel
                style={{
                  marginLeft: 0,
                  cursor: 'default'
                }}
                classes={{ label: classes.label }}
                labelPlacement="top"
                label={
                  <Text color={'gray'}>{`Preço do ${i18n.t(
                    'StructuresName.Course.SingularLower'
                  )}`}</Text>
                }
                control={
                  <Box>
                    <Input
                      placeholder="Ex: 35,99"
                      inputMode="numeric"
                      type="number"
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .replace(/[^0-9,]/g, '')
                          .replace(/(,.*?),/g, '$1');
                      }}
                      {...field}
                    />
                    {error && (
                      <span style={{ color: 'red', fontSize: '12px' }}>
                        {error.message}
                      </span>
                    )}
                  </Box>
                }
              />
            )}
          />
          <Button
            type="submit"
            className={classes.submitButton}
            disabled={loading}
          >
            Salvar
          </Button>
        </Box>
      </Collapse>
    </form>
  );
}

PagseguroInfo.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.number.isRequired,
    monetized_through_pagseguro: PropTypes.bool.isRequired,
    pagseguro_amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired
};
