import React from 'react';

import { useStyles } from './styles';
import { Box, Modal, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

export const StaticFeedbackModal = ({
  open,
  handleClose,
  title,
  description,
  ImageIcon
}) => {
  const classes = useStyles();
  return (
    <Modal open={open} handleClose={handleClose}>
      <Box className={classes.root}>
        <ImageIcon className={classes.imageIcon} />
        <Box className={classes.message}>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.description}>{description}</Typography>
        </Box>
      </Box>
    </Modal>
  );
};

StaticFeedbackModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  ImageIcon: PropTypes.elementType.isRequired
};
