import { isValidCPF } from '~/utils/cpfValidator';

import * as Yup from 'yup';

export const editProfileSchema = Yup.object().shape({
  cpf: Yup.string()
    .nullable()
    .test(
      'is-valid-cpf',
      'CPF inválido.',
      (value) => isValidCPF(value || '') || !value
    )
});
