import React from 'react';

import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

export const ModalHeader = ({ children, classes, endAdornment, ...rest }) => {
  const localClasses = useStyles();
  return (
    <Box className={clsx(localClasses.root, classes.root)} {...rest}>
      <Box className={clsx(localClasses.title, classes.title)}>{children}</Box>
      {endAdornment}
    </Box>
  );
};

ModalHeader.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.shape({
    root: PropTypes.string,
    title: PropTypes.string
  }),
  endAdornment: PropTypes.node
};

ModalHeader.defaultProps = {
  children: null,
  classes: {},
  endAdornment: null
};
