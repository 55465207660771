import React from 'react';

import { useStyles } from './styles';
import DateFnsUtils from '@date-io/date-fns';
import { Box } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import {
  DateTimePicker as MaterialPicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import PropTypes from 'prop-types';

export default function DateTimePickerInput(props) {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div>
        <MaterialPicker
          {...props}
          autoOk
          className={classes.picker}
          inputVariant="outlined"
          format="dd/MM/yyyy H:mm"
          ampm={false}
          InputProps={{
            endAdornment: (
              <Box className={classes.endAdornment}>
                <EventIcon className={classes.icon} />
                <WatchLaterIcon className={classes.icon} />
              </Box>
            )
          }}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
}

DateTimePickerInput.propTypes = {
  input: PropTypes.shape(),
  label: PropTypes.string
};

DateTimePickerInput.defaultProps = {
  input: {},
  label: ''
};
