import React from 'react';

import { useStyles } from './styles';
import { Box, Modal as MUIModal } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

export const Modal = ({
  open,
  onClose,
  disablePortal,
  classes,
  children,
  ...rest
}) => {
  const localClasses = useStyles();
  return (
    <MUIModal
      className={classes.modal}
      open={open}
      onClose={onClose}
      disablePortal={disablePortal}
      {...rest}
    >
      <Box className={clsx(localClasses.root, classes.root)}>{children}</Box>
    </MUIModal>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  disablePortal: PropTypes.bool,
  classes: PropTypes.shape({
    modal: PropTypes.string,
    root: PropTypes.string
  }),
  children: PropTypes.node
};

Modal.defaultProps = {
  onClose: () => {},
  disablePortal: false,
  classes: {},
  children: null
};
