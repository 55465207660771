import { lighten, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  root: {
    backgroundColor: 'white',
    padding: '24px',
    borderRadius: '8px',
    boxShadow: '10px 10px 24px 0px rgba(103, 109, 130, 0.12)',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    maxHeight: '95vh',
    width: '500px',
    maxWidth: '95vw'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  titleIcon: {
    color: theme.palette.error.main,
    width: '32px',
    height: '32px'
  },
  titleText: {
    fontSize: '24px',
    lineHeight: '32px',
    color: theme.palette.activeText.main
  },
  closeIcon: {
    width: '32px',
    height: '32px',
    color: theme.palette.activeText.main
  },
  closeButton: {
    padding: '4px'
  },
  description: {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.activeText.main
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px'
  },
  cancelButton: {
    color: theme.palette.activeText.main,
    textTransform: 'none',
    padding: '8px 24px',
    fontSize: '16px',
    lineHeight: '24px'
  },

  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    textTransform: 'none',
    padding: '8px 24px',
    fontSize: '16px',
    lineHeight: '24px',

    '&:hover': {
      backgroundColor: lighten(theme.palette.error.main, 0.3)
    }
  }
}));
