/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { useHistory, useParams } from 'react-router-dom';

import DeleteConfirmModal from '~/components/molecules/DeleteConfirmModal';
import EnvironmentForm from '~/components/organisms/EnvironmentForm';
import EnvironmentReports from '~/components/organisms/EnvironmentReports';
import GenericFormWrapper from '~/components/organisms/GenericFormWrapper';
import MembersManagement from '~/components/organisms/MembersManagement';
import StepperForm from '~/components/templates/StepperForm';
import i18n from '~/I18n';
import environmentServices from '~/services/environment';
import { getUserFilterReport } from '~/services/reports/environments';
import userEnvironmentAssociationServices from '~/services/user-environment-association';
import { handleSuccess } from '~/utils/auxiliaryFunctions';
import { environmentValidations } from '~/utils/EnvironmentValidation/yup';

import { deleteEnvironment, getEnvironment, updateEnvironment } from './fetch';
import { Grid, useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';

export default function EditEnvironment() {
  const theme = useTheme();

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [, setSubmissionStatus] = useState('');
  const { id: environmentID } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reportURLs, setReportURLs] = useState('');
  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);
  const [mirrorsCount, setMirrorsCount] = useState(0);
  const [defaultValues, setDefaultValues] = useState({
    new_avatar_attributes: null,
    id: null,
    avatarFileName: '',
    description: '',
    generate_link: false,
    keywords: [],
    initials: '',
    name: '',
    state: '',
    regional_group: '',
    city: '',
    cpf_required: false
  });

  const navOptions = [
    i18n.t('Information'),
    i18n.t('Members'),
    i18n.t('Reports')
  ];
  const successMessage = `O ${i18n.t(
    'StructuresName.Environment.SingularLower'
  )} foi atualizado!`;
  const callbackSuccessPath = `/dashboard/environments/${environmentID}`;

  const breadcrumbs = [
    { name: i18n.t('MyEnvironments'), path: '/dashboard/environments' },
    { name: i18n.t('CreateEnvironment'), path: '/create-environment' }
  ];

  const handleExclude = () => {
    openDeleteModal();
  };

  const handleDeleteConfirm = () => {
    deleteEnvironment(environmentID)
      .then(() => {
        enqueueSnackbar(`Ambiente excluído com sucesso!`, {
          variant: 'success'
        });

        history.push(`/dashboard/environments`);
      })
      .catch(() => {
        enqueueSnackbar(`Erro ao excluir ambiente.`, { variant: 'error' });
      })
      .finally(() => {
        closeDeleteModal();
      });
  };

  const deleteMessage = () => {
    if (mirrorsCount) {
      return (
        <>
          O {i18n.t('StructuresName.Environment.SingularLower')}{' '}
          <span style={{ fontWeight: 'bold' }}>{defaultValues.name} </span>
          possui {mirrorsCount} espelho(s) e será permanentemente excluído,
          incluindo todos os dados e arquivos dos seus espelhos. Todos os
          membros irão perder o acesso.
        </>
      );
    } else {
      return (
        <>
          O {i18n.t('StructuresName.Environment.SingularLower')}{' '}
          <span style={{ fontWeight: 'bold' }}>{defaultValues.name}</span>, será
          permanentemente excluído, incluindo todos os dados e arquivos. Todos
          os membros irão perder o acesso.
        </>
      );
    }
  };

  function renderContent(props) {
    const { selectedTab } = props;

    if (selectedTab === 'information') {
      return (
        <GenericFormWrapper
          Form={EnvironmentForm}
          defaultValues={defaultValues}
          resolver={environmentValidations}
          handleExclude={handleExclude}
          excludeTitle="Excluir"
          isSubmitting={isSubmitting}
          {...props}
        />
      );
    } else if (selectedTab === 'reports') {
      return <EnvironmentReports id={defaultValues.id} />;
    } else if (selectedTab === 'members') {
      return (
        <MembersManagement
          structureID={environmentID}
          structureServices={environmentServices}
          userStructureAssociationServices={userEnvironmentAssociationServices}
          reportURLs={reportURLs}
          getUserFilterReport={getUserFilterReport}
        />
      );
    }

    return false;
  }

  const configEnvironment = (data) => {
    setReportURLs(data.report_urls);

    setDefaultValues({
      avatarFileName: data.thumbnails ? data.thumbnails[0].filename : '',
      description: data.description,
      keywords: data.tags,
      id: data.id,
      initials: data.initials,
      name: data.name,
      state: data.state,
      regional_group: data.regional_group,
      city: data.city,
      cpf_required: data.cpf_required,
      current_attendable: data.current_attendable
    });

    setMirrorsCount(data.mirrors_count);

    setIsLoading(false);
  };

  const fetchFailure = () => {
    window.alert(
      `Não foi possível carregar o ${i18n.t(
        'StructuresName.Environment.SingularUpper'
      )}!`
    );
    handleCancel();
    setIsLoading(false);
  };

  useEffect(() => {
    if (environmentID)
      getEnvironment(environmentID)
        .then(configEnvironment)
        .catch(fetchFailure);
  }, [environmentID]);

  function handleCancel() {
    history.push(`/dashboard/environments/${environmentID}`);
  }

  const finalizeSuccess = () => {
    setSubmissionStatus('success');
    handleSuccess(
      successMessage,
      enqueueSnackbar,
      callbackSuccessPath,
      history
    );
  };

  const finalizeFailure = () => {
    alert(
      `Não foi possível atualizar o ${i18n.t(
        'StructuresName.Environment.SingularLower'
      )}!`
    );
    setSubmissionStatus('error');
  };

  function submitForm(fields) {
    if (isSubmitting) return;
    setIsSubmitting(true);
    delete fields.avatarFileName;
    delete fields.generate_link;
    delete fields.link_enrollment;

    updateEnvironment(environmentID, fields)
      .then(finalizeSuccess)
      .catch(finalizeFailure)
      .finally(() => setIsSubmitting(false));
  }

  useEffect(() => {
    document.title = `${theme.title} | Editar ${i18n.t(
      'StructuresName.Environment.SingularLower'
    )}`;
  }, []);

  return isLoading ? (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: '100%' }}
    >
      <Grid item>
        <ReactLoading type="cylon" color={theme.palette.primary.main} />
      </Grid>
    </Grid>
  ) : (
    <>
      <DeleteConfirmModal
        show={showDeleteModal}
        handleClose={closeDeleteModal}
        handleConfirm={handleDeleteConfirm}
        maxWidth={440}
        loading={false}
        showButtons={true}
        title={i18n.t('DeleteEnvironment.title')}
        descriptionMessage={deleteMessage()}
      />
      <StepperForm
        unclickable={false}
        navOptions={navOptions}
        breadcrumbs={breadcrumbs}
        onSubmit={submitForm}
        formTitle={i18n.t('EditEnvironment')}
        submitButtonTitle={i18n.t('Save')}
        handleCancel={handleCancel}
        renderContent={renderContent}
      />
    </>
  );
}
