import * as yup from 'yup';

export const environmentValidations = yup
  .object()
  .shape({
    name: yup
      .string()
      .max(100, 'O Nome deve ter no máximo 100 caracteres.')
      .required('Este campo é obrigatório.'),
    initials: yup
      .string()
      .max(10, 'A abreviação deve ter no máximo 10 caracteres.')
      .required('Este campo é obrigatório.'),
    description: yup
      .string()
      .max(50000, 'A descrição deve ter no máximo 50.000 caracteres.'),
    cpf_required: yup.boolean(),
    minimum_rate_for_completion: yup
      .number()
      .min(0, 'O percentual de conclusão deve ser no mínimo 0.')
      .max(100, 'O percentual de conclusão deve ser no máximo 100.'),
    send_reminder_mail_when_completion_is_below: yup
      .number()
      .min(0, 'O percentual de conclusão deve ser no mínimo 0.')
      .max(100, 'O percentual de conclusão deve ser no máximo 100.')
  })
  .required();
