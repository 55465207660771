import React from 'react';

import Text from '../Text';
import { useStyles } from './styles';
import DateFnsUtils from '@date-io/date-fns';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {
  DatePicker as MaterialPicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import PropTypes from 'prop-types';

export default function DatePicker({ input, label, placeholder }) {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.container}>
        {label && (
          <div className={classes.label}>
            <Text variant="text" color="gray">
              {label}
            </Text>
          </div>
        )}
        <MaterialPicker
          clearable
          clearLabel="Limpar"
          autoOk
          onChange={input.onChange}
          value={input.value}
          className={classes.picker}
          inputVariant="outlined"
          placeholder={placeholder ? placeholder : 'Selecione uma data'}
          format="dd/MM/yyyy"
          InputProps={{
            endAdornment: <DateRangeIcon className={classes.icon} />
          }}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
}

DatePicker.propTypes = {
  input: PropTypes.shape(),
  label: PropTypes.string
};

DatePicker.defaultProps = {
  input: {},
  label: ''
};
