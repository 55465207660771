import React from 'react';

import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

export const ModalFooter = ({
  children,
  className,
  style,
  contentAlignment,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Box
      className={clsx(classes.root, className)}
      style={{
        justifyContent: contentAlignment,
        ...style
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

ModalFooter.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  contentAlignment: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'space-between',
    'space-around',
    'space-evenly'
  ])
};

ModalFooter.defaultProps = {
  children: null,
  className: '',
  style: {},
  contentAlignment: 'flex-end'
};
