/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import DeleteConfirmModal from '~/components/molecules/DeleteConfirmModal';
import GenericFormWrapper from '~/components/organisms/GenericFormWrapper';
import SubjectForm from '~/components/organisms/SubjectForm';
import StepperForm from '~/components/templates/StepperForm';
import i18n from '~/I18n';
import { handleErrors, handleSuccess } from '~/utils/auxiliaryFunctions';
import { subjectValidations } from '~/utils/SubjectValidation/yup';

import { getSubject, updateSubject, deleteSubject } from './fetch';
import { useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';

export default function EditMirroredSubject() {
  const theme = useTheme();
  const { subjectID, spaceID, environmentID } = useParams();

  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);
  const [mirrorsCount, setMirrorsCount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [defaultValues, setDefaultValues] = useState({
    id: null,
    spaceID: spaceID,
    scheduleable: false,
    subject_opening: new Date(),
    subject_closure: new Date(Date.now() + 3600000),
    visible: true,
    lecture_dependency: false,
    lectures: [],
    current_attendable: false
  });

  const navOptions = [i18n.t('Information')];
  const successMessage = `O ${i18n.t(
    'StructuresName.Subjects.SingularLower'
  )} foi atualizado`;
  const callbackSuccessPath = `/dashboard/spaces/${spaceID}`;

  const breadcrumbs = [
    { name: i18n.t('MyEnvironments'), path: '/dashboard/environments' },
    {
      name:
        location?.state?.environmentName ||
        i18n.t('StructuresName.Environment.SingularUpper'),
      path: `/dashboard/environments/${environmentID}`
    }
  ];

  const handleExclude = () => {
    openDeleteModal();
  };

  const deleteMessage = () => {
    if (mirrorsCount) {
      return (
        <>
          O {i18n.t('StructuresName.Subjects.SingularLower')}{' '}
          <span style={{ fontWeight: 'bold' }}>{defaultValues.name} </span>
          possui {mirrorsCount} espelho(s) e será permanentemente excluído,
          incluindo todos os dados e arquivos dos seus espelhos. Todos os
          membros irão perder o acesso.
        </>
      );
    } else {
      return (
        <>
          O {i18n.t('StructuresName.Subjects.SingularLower')}{' '}
          <span style={{ fontWeight: 'bold' }}>{defaultValues.name}</span>, será
          permanentemente excluído, incluindo todos os dados e arquivos. Todos
          os membros irão perder o acesso.
        </>
      );
    }
  };

  const renderContent = (props) => {
    const { selectedTab } = props;

    if (selectedTab === 'information') {
      return (
        <GenericFormWrapper
          Form={SubjectForm}
          defaultValues={defaultValues}
          resolver={subjectValidations}
          handleExclude={handleExclude}
          excludeTitle="Excluir"
          isSubmitting={isSubmitting}
          {...props}
        />
      );
    }

    return false;
  };

  const configDefaultValues = (data) => {
    setDefaultValues({
      spaceID: spaceID,
      id: data.id,
      name: data.name,
      initials: data.initials,
      description: data.description,
      scheduleable: data.scheduleable,
      subject_opening: data.subject_opening,
      subject_closure: data.subject_closure,
      visible: data.visible,
      lecture_dependency: data.lecture_dependency,
      lectures: data.lectures,
      current_attendable: data.current_attendable
    });

    setMirrorsCount(data.mirrors_count);
  };

  const fetchFailure = () => {
    window.alert(
      `Não foi possível carregar o ${i18n.t(
        'StructuresName.Subjects.SingularUpper'
      )}!`
    );
    handleCancel();
  };

  useEffect(() => {
    if (subjectID)
      getSubject(subjectID)
        .then(configDefaultValues)
        .catch(fetchFailure);
  }, [subjectID]);

  const handleCancel = () => {
    history.push(`/dashboard/spaces/${spaceID}`);
  };

  const handleDeleteConfirm = () => {
    deleteSubject(subjectID)
      .then(() => {
        enqueueSnackbar(`Módulo excluído com sucesso!`, { variant: 'success' });

        history.push(`/dashboard/spaces/${spaceID}`);
      })
      .catch(() => {
        enqueueSnackbar(`Erro ao excluir módulo.`, { variant: 'error' });
      })
      .finally(() => {
        closeDeleteModal();
      });
  };

  const finalizeSuccess = () => {
    handleSuccess(
      successMessage,
      enqueueSnackbar,
      callbackSuccessPath,
      history
    );
  };

  const finalizeFailure = (error) => {
    handleErrors(error, enqueueSnackbar);
  };

  function submitForm(fields) {
    if (isSubmitting) return;
    setIsSubmitting(true);
    // Destructure fields and exclude spaceID
    // Exclude spaceID from subject fields
    // eslint-disable-next-line no-unused-vars
    const { id, spaceID, ...subject } = fields;

    // Adjust positions of lectures
    for (let index in subject.lectures) {
      subject.lectures[index].position = parseInt(index) + 1;
    }

    // Create body object for updating subject
    const body = { subject: { finalized: true, ...subject } };

    // Update subject with finalized changes
    updateSubject(id, body)
      .then(finalizeSuccess) // Handle successful update
      .catch(finalizeFailure) // Handle update failure
      .finally(() => setIsSubmitting(false));
  }

  useEffect(() => {
    document.title = `${theme.title} | Editar ${i18n.t(
      'StructuresName.Subjects.SingularLower'
    )}`;
  }, []);

  return (
    <>
      <DeleteConfirmModal
        show={showDeleteModal}
        handleClose={closeDeleteModal}
        handleConfirm={handleDeleteConfirm}
        maxWidth={440}
        loading={false}
        showButtons={true}
        descriptionMessage={deleteMessage()}
        title={i18n.t('DeleteSubject.title')}
      />
      <StepperForm
        navOptions={navOptions}
        breadcrumbs={breadcrumbs}
        onSubmit={submitForm}
        formTitle={i18n.t('EditSubject.title')}
        submitButtonTitle={i18n.t('EditSubject.saveButton')}
        handleCancel={handleCancel}
        renderContent={renderContent}
      />
    </>
  );
}
