import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import { hasCustomAccessTo } from '~/services/AccessControl/accessControl';
import attendanceService from '~/services/attendance';
import { editLectureValidations } from '~/utils/editLectureValidation/yup';
import { IconWrapper } from '~/utils/forms';
import { lectureValidations } from '~/utils/LectureValidation/yup';
import settings from '~settings';

import AdvancedOptions from './AdvancedOptions';
import { handleLectureCreation, deleteAvatar } from './fetch';
import Lectureable from './Lectureable';
import Main from './Main';
import { useStyles } from './styles';
import SubmitSection from './SubmitSection';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { PropTypes } from 'prop-types';

export default function LectureForm({
  handleSuccess,
  handleFailure,
  defaultValues,
  handleClose
}) {
  const classes = useStyles();
  const [progressVideoUpload, setProgressVideoUpload] = useState(0);
  const [videoUploadProgressBarShow, setVideoUploadProgressBarShow] = useState(
    false
  );
  const isAttendanceEnabled = useMemo(() => defaultValues.current_attendable, [
    defaultValues
  ]);
  const { profile: CurrentUser } = useSelector((state) => state.user);
  const edit = !!defaultValues?.id;

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(edit ? editLectureValidations : lectureValidations)
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  const renderCloseIcon = () => {
    return (
      handleClose && (
        <Grid item>
          <IconWrapper
            color="primary"
            fontSize="32px"
            Icon={CloseIcon}
            onClick={handleClose}
          />
        </Grid>
      )
    );
  };

  function openGoogleAuthPopUp() {
    const host = window.location.protocol + '//' + window.location.host;
    const redirectUri = host + '/google_oauth2/callback';
    const clientID = settings.googleAuthClientId;
    const scopes = settings.googleAuthScopes;

    const oauthUrl = `https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?access_type=offline&client_id=${clientID}&prompt=consent&redirect_uri=${redirectUri}&response_type=code&scope=${scopes}&state=&service=lso&o2v=1&theme=glif&flowName=GeneralOAuthFlow`;
    window.open(oauthUrl, '_blank', 'height=600,width=500');
  }

  async function googleAuth(lectureable) {
    const isMeetCondition =
      lectureable.type === 'videocall' &&
      lectureable.videocall.kind === 'google_meet';
    const isLivestreamCondition = lectureable.type === 'youtube_livestream';

    if (isMeetCondition || isLivestreamCondition) {
      await hasCustomAccessTo(`users/${CurrentUser?.id}`, 'google');
    }
  }

  const onSubmit = async (data) => {
    // GoogleAuth handling
    try {
      await googleAuth(data.lectureable);
    } catch {
      openGoogleAuthPopUp();
      return;
    }
    try {
      const imagesTrack = methods.watch('imageTrack');
      if (imagesTrack) {
        for (let deleteableImage of imagesTrack.removed) {
          if (!imagesTrack.loaded.includes(deleteableImage)) {
            deleteAvatar(deleteableImage, defaultValues.subjectID);
          }
        }
      }

      //disable attendance if not enabled
      if (!data.attendance?.enable && data.attendance?.id)
        await attendanceService.destroy(data.attendance?.id);
      if (!data.attendance?.enable) delete data.attendance;

      const response = await handleLectureCreation(
        data,
        defaultValues,
        setProgressVideoUpload,
        setVideoUploadProgressBarShow
      );
      handleSuccess(response);
    } catch (e) {
      console.log(e);
      handleFailure(e.response.data.error);
    }
  };

  const isGoogleMeetTitleInvalid = () => {
    if (methods.watch('lectureable.videocall.kind') == 'google_meet') {
      const data = methods.watch('lectureable[videocall][google_meet][title]');
      if (!data || data.trim().length <= 0) {
        methods.setError('lectureable[videocall][google_meet][title]', {
          type: 'invalidTitle',
          message: 'O Titulo é obrigatório.'
        });
        return true;
      }
      if (data.trim().length > 256) {
        methods.setError('lectureable[videocall][google_meet][title]', {
          type: 'invalidTitle',
          message: 'O titulo deve ter no máximo 256 caracteres.'
        });
        return true;
      }
    }
    return false;
  };

  const handlePropagation = (event) => {
    if (typeof event.preventDefault === 'function') {
      event.preventDefault();
    }
    if (typeof event.stopPropagation === 'function') {
      event.stopPropagation();
    }

    if (isGoogleMeetTitleInvalid()) return;

    methods.handleSubmit(onSubmit)(event);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handlePropagation}>
        <Box className={classes.container}>
          <Box
            className={classes.root}
            px={{ xs: 2, sm: 4 }}
            py={{ xs: 1, sm: 2 }}
          >
            <div className={classes.field}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Text color="primary" variant="lgTitle">
                    {' '}
                    {edit ? 'Editar' : 'Adicionar'}{' '}
                    {`${i18n.t('StructuresName.Lectures.SingularLower')}`}{' '}
                  </Text>
                </Grid>

                {renderCloseIcon()}
              </Grid>
            </div>
            <div className={classes.field}>
              <Main />
            </div>
            <div className={classes.field}>
              <Lectureable
                videoUploadProgressBarShow={videoUploadProgressBarShow}
                progressVideoUpload={progressVideoUpload}
                defaultValues={defaultValues}
              />
            </div>
            <div className={classes.field}>
              <AdvancedOptions isAttendanceEnabled={isAttendanceEnabled} />
            </div>
            <div className={classes.field}>
              <SubmitSection />
            </div>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}

LectureForm.propTypes = {
  defaultValues: PropTypes.object,
  handleFailure: PropTypes.func,
  handleSuccess: PropTypes.func,
  handleClose: PropTypes.func
};

LectureForm.defaultProps = {
  defaultValues: {},
  handleFailure: async () => {},
  handleSuccess: async () => {},
  handleClose: async () => {}
};
