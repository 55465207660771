import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
    flexWrap: 'wrap'
  },
  textfield: {
    flex: 3,
    [theme.breakpoints.down('md')]: {
      minWidth: '100%'
    }
  },
  inputBox: {
    fontSize: '16px',
    lineHeight: '24px'
  },
  input: {
    padding: '12px',
    color: theme.palette.activeText.main
  },
  inputIcon: {
    fontSize: '24px',
    color: '#A8B1CE'
  },
  dataPicker: {
    minWidth: '160px',
    flex: 1
  },
  newAttendanceButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    textTransform: 'none',
    padding: '8px 16px',
    minWidth: 'fit-content',
    width: 'fit-content',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  newAttendanceIcon: {
    fontSize: '24px',
    width: '24px',
    height: '24px'
  },
  newAttendanceText: {
    fontSize: '16px',
    lineHeight: '24px',
    whiteSpace: 'nowrap'
  }
}));
