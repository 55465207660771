import React, { useState } from 'react';

import { update } from '~/services/presence';

import { useStyles } from './styles';
import { MenuItem, Select, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const selectOptions = {
  present: 'Presente',
  absent: 'Ausente',
  justified: 'Justificada',
  unknown: 'Não Atribuída'
};

export const FrequencyCell = ({ disabled, presence }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = async (newStatusValue) => {
    if (loading) return;
    try {
      setLoading(true);
      await update(presence.id, newStatusValue);
    } catch {
      enqueueSnackbar('Erro ao atualizar status de presença', {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Select
      variant="outlined"
      fullWidth
      displayEmpty
      defaultValue={presence.status}
      open={open}
      onClick={() => !disabled && setOpen(!open)}
      onClose={() => setOpen(false)}
      onOpen={() => !disabled && setOpen(true)}
      className={clsx(classes.root, disabled && classes.disabled)}
      disabled={disabled || loading}
      renderValue={(value) => (value ? selectOptions[value] : '-')}
      onChange={(event) => handleChange(event.target.value)}
      IconComponent={(props) =>
        disabled ? (
          <Tooltip
            title="Membro não possui acesso a mídia"
            arrow
            placement="top"
            classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
          >
            <InfoRoundedIcon className={classes.icon} />
          </Tooltip>
        ) : (
          <ExpandMoreIcon {...props} />
        )
      }
      classes={{
        select: clsx(classes.select, disabled && classes.disabled),
        icon: classes.icon
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        getContentAnchorEl: null
      }}
    >
      {Object.entries(selectOptions).map(([value, label]) => (
        <MenuItem key={value} value={value} className={classes.menuItem}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

FrequencyCell.propTypes = {
  disabled: PropTypes.bool.isRequired,
  presence: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    status: PropTypes.oneOf(['present', 'absent', 'justified', 'unknown'])
      .isRequired
  }).isRequired
};
