import React, { useMemo, useState } from 'react';

import { useStyles } from './styles';
import {
  Box,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MUIPagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

export const Pagination = ({
  totalItens,
  handleChange,
  page = 1,
  perPage = 50
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const totalPages = useMemo(() => Math.ceil(totalItens / perPage), [
    totalItens,
    perPage
  ]);

  const renderPaginationItem = (item) => {
    switch (item.page) {
      case page + 3: {
        if (page === 1) {
          const endDotsItem = { ...item, type: 'end-ellipsis' };
          return <PaginationItem {...endDotsItem} component="span" />;
        }
        return null;
      }
      case page + 2: {
        if (page === 1) {
          return <PaginationItem {...item} />;
        }
        const endDotsItem = { ...item, type: 'end-ellipsis' };
        return <PaginationItem {...endDotsItem} component="span" />;
      }
      case page + 1: {
        return <PaginationItem {...item} />;
      }
      case page: {
        return <PaginationItem {...item} />;
      }
      case page - 1: {
        return <PaginationItem {...item} />;
      }
      case page - 2: {
        if (page === totalPages) {
          return <PaginationItem {...item} />;
        }
        const startDotsItem = { ...item, type: 'start-ellipsis' };
        return <PaginationItem {...startDotsItem} component="span" />;
      }
      case page - 3: {
        if (page === totalPages) {
          const startDotsItem = { ...item, type: 'start-ellipsis' };
          return <PaginationItem {...startDotsItem} component="span" />;
        }
        return null;
      }
    }
  };

  const handleChangePerPage = (e) => {
    handleChange(1, e.target.value);
  };

  const handleChangePage = (event, value) => {
    handleChange(value, perPage);
  };

  return (
    <>
      {totalItens > 50 ? (
        <Box className={classes.root}>
          <MUIPagination
            count={totalPages}
            shape="rounded"
            page={page}
            onChange={handleChangePage}
            siblingCount={isMdDown ? totalPages : undefined}
            renderItem={isMdDown ? renderPaginationItem : undefined}
            classes={{
              ul: classes.paginationUl
            }}
          />
          <Box className={classes.perPageContainer}>
            <Typography className={classes.perPageText}>
              Itens por página:
            </Typography>
            <Select
              variant="outlined"
              className={classes.selectRoot}
              open={open}
              onClick={() => setOpen(!open)}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              IconComponent={(props) => <ExpandMoreIcon {...props} />}
              classes={{
                select: classes.select,
                icon: classes.selectIcon
              }}
              value={perPage}
              onChange={handleChangePerPage}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                getContentAnchorEl: null
              }}
            >
              <MenuItem value={50} className={classes.menuItem}>
                50
              </MenuItem>
              <MenuItem value={100} className={classes.menuItem}>
                100
              </MenuItem>
              <MenuItem value={200} className={classes.menuItem}>
                200
              </MenuItem>
            </Select>
          </Box>
        </Box>
      ) : null}
    </>
  );
};
