import React from 'react';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const Sidebar = ({ currentTab, setCurrentTab, displayAttendance }) => {
  const classes = useStyles();

  return (
    <Box className={classes.sidebar}>
      <Box className={classes.sidebarContent}>
        <Text
          color={currentTab === 'content' ? 'primary' : 'gray'}
          variant="environmentSidebarText"
          style={{ marginBottom: 15 }}
          onClick={() => {
            setCurrentTab('content');
          }}
        >
          {i18n.t('Content')}
        </Text>
        <Text
          color={currentTab === 'members' ? 'primary' : 'gray'}
          variant="environmentSidebarText"
          style={{ marginBottom: 15 }}
          onClick={() => {
            setCurrentTab('members');
          }}
        >
          {i18n.t('Members')}
        </Text>
        {displayAttendance && (
          <Text
            color={currentTab === 'attendance' ? 'primary' : 'gray'}
            variant="environmentSidebarText"
            style={{ marginBottom: 15 }}
            onClick={() => {
              setCurrentTab('attendance');
            }}
          >
            Frequência
          </Text>
        )}
        <Text
          color={currentTab === 'classifications' ? 'primary' : 'gray'}
          variant="environmentSidebarText"
          style={{ marginBottom: 15 }}
          onClick={() => {
            setCurrentTab('classifications');
          }}
        >
          Classificação
        </Text>
      </Box>
    </Box>
  );
};

Sidebar.propTypes = {
  contentIsSelected: PropTypes.bool,
  setContentIsSelected: PropTypes.func,
  contentClassificationIsSelected: PropTypes.bool,
  setContentClassificationIsSelected: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.shape()),
  handleGetUsers: PropTypes.func
};

Sidebar.defaultProps = {
  contentIsSelected: false,
  setContentIsSelected: null,
  contentClassificationIsSelected: false,
  setContentClassificationIsSelected: null,
  users: [],
  handleGetUsers: null
};

export default Sidebar;
