import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px',
    overflow: 'hidden',
    maxWidth: '100%'
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
    overflow: 'hidden'
  },
  primary: {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%'
  },
  secondary: {
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.palette.activeText.main
  },
  iconButton: {
    padding: '4px'
  },
  icon: {
    width: '24px',
    height: '24px'
  },
  tooltip: {
    fontSize: '14px',
    lineHeight: '20px',
    backgroundColor: '#A8B1CE',
    color: 'white'
  },
  tooltipArrow: {
    color: '#A8B1CE'
  }
}));
