import React, { useEffect, useState } from 'react';

import { ReactComponent as TropicalIslandImage } from '~/assets/images/tropical-island-cuate.svg';
import GenericFeedbackLayout from '~/components/molecules/GenericFeedbackLayout';
import { Pagination } from '~/components/molecules/Pagination';
import { TableRowContent } from '~/components/organisms/AttendanceUserRecords/TableRowContent';

import { useStyles } from './styles';
import { TableHeaderContent } from './TableHeaderContent';
import { Box, CircularProgress, TableRow } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';

export const AttendanceUserRecords = ({ getPresences }) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [loading, setLoading] = useState(false);
  const [totalItens, setTotalItens] = useState(0);

  function formatDate(date) {
    if (!date) return null;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  }

  const handleGetPresences = async (page, perPage) => {
    setPage(page);
    setPerPage(perPage);
    setLoading(true);
    const data = await getPresences(page, perPage, dateFilter);
    setLoading(false);
    setRows(data.presences);
    setTotalItens(data.pagination.total_presences);
  };

  useEffect(() => {
    async function log() {
      setLoading(true);
      const data = await getPresences(1, 50, {
        date: dateFilter
      });
      setLoading(false);
      setRows(data.presences);
      setTotalItens(data.pagination.total_presences);
    }

    log();
  }, [dateFilter]);

  if (!loading && rows.length === 0)
    return (
      <GenericFeedbackLayout
        ImageIcon={TropicalIslandImage}
        title="Lista de Frequência Vazia!"
        description="Essa estrutura não possui nenhuma ata de frequência registrada."
      />
    );

  return (
    <Box className={classes.root}>
      {loading ? (
        <Box className={classes.feedbackRoot}>
          <CircularProgress size={80} />
        </Box>
      ) : (
        <>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableHeaderContent
                  dateFilter={dateFilter}
                  setDateFilter={(date) => setDateFilter(formatDate(date))}
                />
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    style={{
                      backgroundColor: index % 2 === 0 ? '#fff' : '#F2F2F7'
                    }}
                  >
                    <TableRowContent presence={row} key={row.id} />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <Pagination
        totalItens={totalItens}
        page={page}
        perPage={perPage}
        handleChange={handleGetPresences}
      />
    </Box>
  );
};
