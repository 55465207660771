import React, { useEffect, useState } from 'react';

import { getSettings } from '~/services/settings';

import MainInfo from './MainInfo';
import Options from './Options';
import { useStyles } from './styles';
import { Box, CircularProgress } from '@material-ui/core';

export default function EnvironmentForm() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [cpfRequired, setCpfRequired] = useState(false);

  const getPLatformSettings = async () => {
    setLoading(true);
    const data = await getSettings();
    const settings = data.reduce((acc, setting) => {
      acc[setting.key] = setting.value || null;
      return acc;
    }, {});
    setCpfRequired(!!Number(settings['feature.cpf_required']));
    setLoading(false);
  };

  useEffect(() => {
    getPLatformSettings();
  }, []);

  return (
    <>
      {!loading ? (
        <div className={classes.content}>
          <MainInfo />
          <Options cpfIsRequired={cpfRequired} />
        </div>
      ) : (
        <Box className={classes.loadingContainer}>
          <CircularProgress size={80} />
        </Box>
      )}
    </>
  );
}
