import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius
  },
  header: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: 16
  },
  period: {
    fontSize: 16,
    border: `1px solid #A8B1CE`,
    padding: theme.spacing(1.8),
    borderRadius: theme.shape.borderRadius
  },
  dateField: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius
  },
  dateContainer: {
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  dateIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: 23
  },
  dateText: {
    flexGrow: 1,
    color: theme.palette.activeText.main,
    fontSize: 15
  },
  infoBox: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    height: '100%'
  },
  headTitle: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(5),
    color: theme.palette.activeText.main,
    fontSize: 15
  },
  info: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(5),
    color: '#A8B1CE',
    fontSize: 15
  },
  secondBox: {
    display: 'flex',
    flexDirection: 'column'
  }
}));
