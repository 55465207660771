import * as yup from 'yup';

export const CertificateValidations = (isEdit, hasPresence) => {
  return yup
    .object()
    .shape({
      responsibleName: yup
        .string()
        .required('O nome do responsável é obrigatório.'),
      responsiblePosition: yup
        .string()
        .required('O cargo do responsável é obrigatório.'),
      organizationName: yup
        .string()
        .required('O nome da organização é obrigatório.'),
      minimumPercentage: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(
          'A percentagem mínima para geração do certificado é obrigatória.'
        )
        .min(0, 'A percentagem mínima não pode ser negativa.')
        .max(100, 'A percentagem máxima não pode ser maior que 100.'),
      minPresence: !hasPresence
        ? yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .notRequired()
        : yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required(
              'A percentagem mínima de presenças para geração do certificado é obrigatória.'
            )
            .min(0, 'A percentagem mínima de presenças não pode ser negativa.')
            .max(
              100,
              'A percentagem máxima de presenças não pode ser maior que 100.'
            ),
      startDate: yup
        .date()
        .nullable()
        .when('isCoursePeriod', {
          is: true,
          then: yup
            .date()
            .required(
              'A data de início é obrigatória se o período estiver habilitado.'
            ),
          otherwise: yup
            .date()
            .nullable()
            .notRequired()
        })
        .test(
          'start-before-end',
          'A data de início não pode ser posterior à data de término.',
          function(value) {
            let endDate = this.parent.endDate;
            return !endDate || !value || new Date(value) <= new Date(endDate);
          }
        ),
      endDate: yup
        .date()
        .nullable()
        .when('isCoursePeriod', {
          is: true,
          then: yup
            .date()
            .required(
              'A data de término é obrigatória se o período estiver habilitado.'
            ),
          otherwise: yup
            .date()
            .nullable()
            .notRequired()
        })
        .test(
          'end-after-start',
          'A data de término não pode ser anterior à data de início.',
          function(value) {
            let startDate = this.parent.startDate;
            return (
              !startDate || !value || new Date(value) >= new Date(startDate)
            );
          }
        ),

      signature: isEdit
        ? yup.mixed().notRequired()
        : yup.mixed().required('A assinatura é obrigatória.')
    })
    .required();
};
