import React, { useEffect, useState } from 'react';

import LaPlaceContext from '~/components/contexts/LaPlaceContext';
import LectureForm from '~/components/organisms/LectureForm';
import { useStyles } from '~/components/organisms/SubjectForm/Content/styles';
import i18n from '~/I18n';
import { findByID } from '~/services/Lecture';
import { sanitizeLecture } from '~/utils/auxiliaryFunctions';

import { Backdrop, Fade, Grid, Modal } from '@material-ui/core';
import { PropTypes } from 'prop-types';

export default function EditLectureModal({
  handleClose,
  open,
  lectureID,
  updateLectures
}) {
  const classes = useStyles();

  const lectureFields = [
    'id',
    'name',
    'expiration_date',
    'markable',
    'mural_enabled',
    'downloadable_status',
    'notification',
    'can_edit_content',
    'lectureable',
    'subjectID',
    'private_mural',
    'mandatory_status_file',
    'is_mirror',
    'attendance',
    'current_attendable'
  ];

  const [defaultValues, setDefaultValues] = useState(null);

  useEffect(() => {
    if (!open) return;
    findByID(lectureID).then((lecture) => {
      let newLecture = {};
      let processed = sanitizeLecture(lecture);

      for (let i = 0; i < lectureFields.length; i++) {
        const key = lectureFields[i];
        if (processed[key] === undefined) continue;
        newLecture[key] = processed[key];
      }

      const defaultAttendance = newLecture.attendance;
      setDefaultValues({
        ...newLecture,
        attendance: { ...defaultAttendance, enable: !!defaultAttendance }
      });
    });
  }, [open]);

  const handleLectureEditSuccess = (data) => {
    updateLectures(data);
    handleClose();
  };

  const handleLectureEditFailure = () => {
    window.alert(
      `Não foi possível editar a ${i18n.t(
        'StructuresName.Lectures.SingularLower'
      )}`
    );
    handleClose();
  };

  return (
    <Modal
      id="modal-modal"
      disableEnforceFocus
      aria-labelledby="lecture-form"
      aria-describedby="modal-for-lecture-form"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      onScroll={(e) => e.stopPropagation()}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Grid container>
          <Grid onClick={handleClose} xs={1} sm={2} lg={3} xl={4}></Grid>

          <Grid xs={10} sm={8} lg={6} xl={4}>
            {defaultValues && (
              <LaPlaceContext.Provider
                value={{
                  handleLectureEditSuccess,
                  subjectID: defaultValues?.subjectID
                }}
              >
                <LectureForm
                  defaultValues={defaultValues}
                  handleSuccess={handleLectureEditSuccess}
                  handleFailure={handleLectureEditFailure}
                  handleClose={handleClose}
                />
              </LaPlaceContext.Provider>
            )}
          </Grid>

          <Grid onClick={handleClose} xs={1} sm={2} lg={3} xl={4}></Grid>
        </Grid>
      </Fade>
    </Modal>
  );
}

EditLectureModal.propTypes = {
  lecture: PropTypes.shape(),
  open: PropTypes.string,
  lectureID: PropTypes.number,
  handleClose: PropTypes.func,
  updateLectures: PropTypes.func
};

EditLectureModal.defaultProps = {
  lecture: {},
  open: false,
  lectureID: -1,
  handleClose: async () => {},
  updateLectures: async () => {}
};
