import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '8px',
    padding: '24px',
    width: 'fit-content',
    maxWidth: '95%',
    maxHeight: '95%',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    backgroundColor: 'white'
  }
});
