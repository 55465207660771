import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    width: '308px',
    maxWidth: '100%'
  },
  label: {
    fontSize: '14px',
    lineHeight: '24px',
    color: theme.palette.activeText.main
  },
  errorMessage: {
    fontSize: '14px',
    lineHeight: '24px',
    color: theme.palette.error.main
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '16px'
  },
  textInput: {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.activeText.main,
    padding: '12px 16px'
  },
  textfield: {
    marginTop: '4px'
  },
  cancelButton: {
    padding: '8px 24px',
    fontSize: '16px',
    lineHeight: '24px',
    textTransform: 'none',
    color: theme.palette.activeText.main
  },
  confirmButton: {
    alignSelf: 'flex-end',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: '8px 24px',
    borderRadius: '4px',
    textTransform: 'none',

    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  }
}));
