import { makeStyles } from '@material-ui/core/styles';

const userNameCellWidth = 386;
const frequencyCellWidth = 282;
const responsiveUserNameCellWidth = 240;
const responsiveFrequencyCellWidth = 240;

export const useStyles = makeStyles((theme) => ({
  feedbackRoot: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    overflow: 'hidden'
  },
  table: {
    overflow: 'auto',
    borderRadius: '8px'
  },
  headerCellName: {
    position: 'sticky',
    zIndex: 3,
    minWidth: userNameCellWidth,
    maxWidth: `${userNameCellWidth}px !important`,
    borderRight: '1px solid #E0E0E0',
    backgroundColor: '#F2F2F7',
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.activeText.main,

    [theme.breakpoints.down('md')]: {
      minWidth: responsiveUserNameCellWidth,
      maxWidth: `${responsiveUserNameCellWidth}px !important`
    }
  },
  headerCell: {
    minWidth: frequencyCellWidth,
    maxWidth: `${frequencyCellWidth}px !important`,
    overflow: 'hidden',
    borderRight: '1px solid #E0E0E0',
    backgroundColor: '#F2F2F7',

    [theme.breakpoints.down('md')]: {
      minWidth: responsiveFrequencyCellWidth,
      maxWidth: `${responsiveFrequencyCellWidth}px !important`
    }
  },
  userNameCell: {
    position: 'sticky',
    zIndex: 2,
    minWidth: userNameCellWidth,
    maxWidth: `${userNameCellWidth}px !important`,
    left: 0,
    borderRight: '1px solid #E0E0E0',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      minWidth: responsiveUserNameCellWidth,
      maxWidth: `${responsiveUserNameCellWidth}px !important`
    }
  },
  frequencyCell: {
    minWidth: frequencyCellWidth,
    maxWidth: `${frequencyCellWidth}px !important`,
    borderRight: '1px solid #E0E0E0',
    padding: '16px 32px',

    [theme.breakpoints.down('md')]: {
      minWidth: responsiveFrequencyCellWidth,
      maxWidth: `${responsiveFrequencyCellWidth}px !important`
    }
  }
}));
