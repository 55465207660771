import React from 'react';

import { useAttendanceContext } from '~/contexts/AttendanceManager';

import { FrequencyCell } from './FrequencyCell';
import { HeaderCell } from './HeaderCell';
import { useStyles } from './styles';
import { UserCell } from './UserCell';
import { Box, CircularProgress } from '@material-ui/core';
import MUITable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export function Table() {
  const classes = useStyles();
  const { presences, users, attendances, loading } = useAttendanceContext();

  if (loading) {
    return (
      <Box className={classes.feedbackRoot}>
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <>
      <TableContainer className={classes.table}>
        <MUITable stickyHeader style={{ tableLayout: 'fixed', width: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell
                key={'name'}
                align="left"
                className={classes.headerCellName}
              >
                Nome
              </TableCell>
              {attendances.length > 0 &&
                Array.from({
                  length: attendances.length > 4 ? attendances.length : 4
                }).map((_, index) => {
                  const attendance = attendances[index];
                  return (
                    <TableCell key={index} className={classes.headerCell}>
                      {attendance ? (
                        <HeaderCell attendance={attendance} />
                      ) : (
                        <div style={{ height: '100%', width: '100%' }} />
                      )}
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((user, rowIndex) => {
                const id = user.id;
                return (
                  <TableRow key={id}>
                    <TableCell
                      key={rowIndex}
                      className={classes.userNameCell}
                      style={{
                        backgroundColor:
                          rowIndex % 2 === 0 ? 'white' : '#F2F2F7'
                      }}
                    >
                      <UserCell user={user} />
                    </TableCell>
                    {presences &&
                      Object.keys(presences).length > 0 &&
                      Array.from({
                        length:
                          presences[id]?.length > 4 ? presences[id].length : 4
                      }).map((_, index) => {
                        const presence = presences[id][index];
                        return (
                          <TableCell
                            key={presence?.id || index}
                            className={classes.frequencyCell}
                            style={{
                              backgroundColor:
                                rowIndex % 2 === 0 ? 'white' : '#F2F2F7'
                            }}
                          >
                            {presence &&
                            presence.attendance.id ===
                              attendances[index]?.id ? (
                              <FrequencyCell
                                presence={presence}
                                userID={id}
                                disabled={!presence.user_has_access}
                              />
                            ) : (
                              <div style={{ height: '100%', width: '100%' }} />
                            )}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </MUITable>
      </TableContainer>
    </>
  );
}
