import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  picker: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '4px',
    '& input': {
      fontSize: 16,
      padding: 12
    }
  },
  label: {
    marginTop: 10,
    marginBottom: 5
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: '24px'
  }
}));
