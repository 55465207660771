import React, { isValidElement } from 'react';

import { useStyles } from './styles';
import { Box, Fade, Modal, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

export const ManagerAttendanceModal = ({ children, title, open, onClose }) => {
  const classes = useStyles();
  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Fade in={open}>
        <Box className={classes.root}>
          <Typography className={classes.title}>{title}</Typography>
          {isValidElement(children)
            ? React.cloneElement(children, { onCloseModal: onClose })
            : children}
        </Box>
      </Fade>
    </Modal>
  );
};

ManagerAttendanceModal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
