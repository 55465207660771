import React, { useEffect, useState } from 'react';

import { ReactComponent as MaintenanceImage } from '~/assets/images/maintenance-cuate.svg';
import DatePicker from '~/components/atoms/DatePicker';
import { StaticFeedbackModal } from '~/components/molecules/StaticFeedbackModal';
import { ManagerAttendanceModal } from '~/components/organisms/AttendanceManager/ManagerAttendanceModal';
import { CreateEditAttendanceForm } from '~/components/organisms/AttendanceManager/ManagerAttendanceModal/CreateEditAttendanceForm';
import { useAttendanceContext } from '~/contexts/AttendanceManager';

import { useStyles } from './styles';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'debounce';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

export const SubHeader = () => {
  const [rendering, setRendering] = useState(false);
  const {
    service,
    structureID,
    updatePresencesWithFilters,
    presences,
    attendances
  } = useAttendanceContext();
  const [filters, setFilters] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [dataFilterValue, setDateFilterValue] = useState(null);
  const [openCreateAttendanceModal, setOpenCreateAttendanceModal] = useState(
    false
  );

  function formatDate(date) {
    if (!date) return null;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  }

  const debounceSearch = debounce((searchValue) => {
    setFilters((prev) => ({ ...prev, search: searchValue }));
  }, 500);

  const handleSearch = (event) => {
    if (debounceSearch) debounceSearch.clear();
    debounceSearch(event.target.value);
  };

  const handleDataFilter = (date) => {
    setDateFilterValue(date);
    setFilters((prev) => ({
      ...prev,
      date: formatDate(date)
    }));
  };

  const handleCreateAttendance = async (newAttendanceData) => {
    try {
      await service.createAttendance(structureID, newAttendanceData);
    } catch {
      enqueueSnackbar('Erro ao criar nova ata de frequência', {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    if (presences && attendances.length * Object.keys(presences).length > 100)
      setRendering(true);

    setTimeout(async () => {
      updatePresencesWithFilters(filters);
      setRendering(false);
    }, 1);
  }, [filters]);

  return (
    <>
      <Box className={classes.container}>
        <TextField
          inputProps={{
            className: classes.input
          }}
          InputProps={{
            className: classes.inputBox,
            startAdornment: <SearchIcon className={classes.inputIcon} />
          }}
          placeholder="Buscar membro"
          variant="outlined"
          onChange={handleSearch}
          className={classes.textfield}
        />
        <Box className={classes.dataPicker}>
          <DatePicker
            placeholder="Filtrar por data"
            input={{
              value: dataFilterValue,
              onChange: handleDataFilter
            }}
          />
        </Box>
        <Button
          onClick={() => setOpenCreateAttendanceModal(true)}
          className={classes.newAttendanceButton}
          disableRipple
        >
          <AddRoundedIcon className={classes.newAttendanceIcon} />
          <Typography className={classes.newAttendanceText}>
            Nova ata de frequência
          </Typography>
        </Button>
        <ManagerAttendanceModal
          open={openCreateAttendanceModal}
          onClose={() => {
            setOpenCreateAttendanceModal(false);
          }}
          title="Novo registro de frequência"
        >
          <CreateEditAttendanceForm onSubmit={handleCreateAttendance} />
        </ManagerAttendanceModal>
      </Box>
      <StaticFeedbackModal
        open={rendering}
        title=" Hmm, isso está demorando um pouco mais que o planejado..."
        description={
          <>
            <p style={{ marginBottom: '8px' }}>Por favor, aguarde!</p>
            <p>
              Estamos processando as últimas informações, vamos te avisar se
              algo der errado.
            </p>
          </>
        }
        ImageIcon={MaintenanceImage}
      />
    </>
  );
};

SubHeader.propTypes = {
  setSearch: PropTypes.func.isRequired,
  setDateFilter: PropTypes.func.isRequired
};
